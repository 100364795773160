import s from "assets/scss/Works.module.scss"
import { useEffect, useRef, useState } from "react"

import cx from "classnames"
import gsap from "gsap"
import { Helmet } from "react-helmet"
import { useQuery } from "react-query"

import api from "api"
import { IWorkCard } from "api/types"
import Dropdown from "components/Dropdown"
import FooterMain from "components/FooterMain"
import WorkCard from "components/WorkCard"

import { useWindowSize } from "hooks"
import { useFilterStore } from "store/filterStore"
import { useThemeStore } from "store/themeStore"

import searchIcon from "assets/icon/search-icon.svg"
import notFound from "assets/img/not-found-works.svg"
import { Link } from "react-router-dom"
import { useCursorStore } from "store/cursorStore"

interface Option {
  id?: string
  title: string
}

const Works = () => {
  // const keepScrollingRef = useRef(null)
  // const keepScrollingTextRef = useRef(null)
  const windowSize = useWindowSize()
  const themeStore = useThemeStore()
  const filtersRef = useRef<any>(null)
  const projectsRef = useRef(null)
  const searchInputRef = useRef(null)

  const { filters, setService, setClientType, resetFilters } = useFilterStore()
  const [keyword, setKeyword] = useState<string>("")

  // const [maxItemNumber, setMaxItemNumber] = useState(6)

  // INIT
  useEffect(() => {
    themeStore.setTheme("LIGHT")

    window.onbeforeunload = () => {
      resetFilters()
    }

    return () => {
      themeStore.setTheme("DARK")
    }
  }, [])

  const fetchclientType = async () => {
    const res = await api.get("/works/clientTypes.php")
    return res.data
  }

  const { isLoading: isClientTypeLoading, data: clientTypes } = useQuery<Option[], Error>(
    ["client-type"],
    fetchclientType,
    {
      enabled: true,
      retry: 2,
    }
  )

  const fetchServices = async () => {
    const res = await api.get("/works/services.php")
    return res.data
  }

  const { isLoading: isServicesLoading, data: services } = useQuery<Option[], Error>(["services"], fetchServices, {
    enabled: true,
    retry: 2,
  })

  const fetchWorks = async () => {
    const params = { ...filters, keyword }
    const res = await api.post("/works/works.php", { ...params })
    return res.data
  }

  const { isLoading: isWorksloading, data: works } = useQuery<IWorkCard[] | null, Error>(
    ["works", filters, keyword],
    fetchWorks,
    {
      enabled: true,
      retry: 2,
      onError: (err: any) => {
        console.log("FETCH ERROR", err.response?.data || err)
      },
    }
  )

  // TODO: DRY NIMATION FUNCTION
  // const asyncAnim = (handler: any) => {
  //   gsap.to(projectsRef.current, {
  //     autoAlpha: 0,
  //     duration: 0.4,
  //     pointerEvents: "none",
  //     onComplete: () => {
  //       handler()
  //       gsap.to(projectsRef.current, {
  //         delay: 0.4,
  //         autoAlpha: 1,
  //         pointerEvents: "auto",
  //       })
  //     },
  //   })
  // }

  const cursorStore = useCursorStore()

  useEffect(() => {
    cursorStore.toggleDefault()
  }, [filters])

  const clearFilters = () => {
    gsap.to(projectsRef.current, {
      autoAlpha: 0,
      duration: 0.4,
      pointerEvents: "none",
      onComplete: () => {
        setClientType("")
        setService("")
        gsap.to(projectsRef.current, {
          delay: 0.4,
          autoAlpha: 1,
          pointerEvents: "auto",
        })
      },
    })
  }

  const getSelectedOption = (options: Option[], id: string) => {
    const filtered = options.filter((option) => {
      return option.id === id
    })
    return filtered[0]
  }

  const handleClientType = (id: string) => {
    gsap.to(projectsRef.current, {
      autoAlpha: 0,
      duration: 0.4,
      pointerEvents: "none",
      onComplete: () => {
        setClientType(id)
        if (keyword !== "") {
          setKeyword("")
        }
        gsap.to(projectsRef.current, {
          delay: 0.4,
          autoAlpha: 1,
          pointerEvents: "auto",
        })
      },
    })
  }

  const handleServices = (id: string) => {
    gsap.to(projectsRef.current, {
      autoAlpha: 0,
      duration: 0.4,
      pointerEvents: "none",
      onComplete: () => {
        setService(id)
        if (keyword !== "") {
          setKeyword("")
        }
        gsap.to(projectsRef.current, {
          delay: 0.4,
          autoAlpha: 1,
          pointerEvents: "auto",
        })
      },
    })
  }

  const handleSearch = (e: any) => {
    const keyword = e.currentTarget.value

    if (filters.clientType !== "" || filters.service !== "") {
      clearFilters()
    }
    setKeyword(keyword)
  }

  const handleFocus = (e: any) => {
    e.preventDefault()

    if (e.type === "focus" && e) {
      e.target.labels[0].style.opacity = "0"
    } else if (e.type === "blur" && e) {
      e.target.labels[0].style.opacity = "0.3"
    }
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>JUST DESIGN FX | Works</title>
        <meta
          name="description"
          content="Take a look at what we’ve done. View our selection of branding and website projects."
        ></meta>
        <link rel="canonical" href="https://justdesignfx.com/works" />
      </Helmet>
      <main className={s.works}>
        <section className={s.worksFilter} ref={filtersRef}>
          <h1 className={s.title}>OUR WORKS</h1>

          <form className={s.formContainer} onSubmit={(e) => e.preventDefault()}>
            {services && Array.isArray(services) && (
              <div className={s.inputContainer}>
                <Dropdown
                  options={services}
                  onChange={handleServices}
                  defaultValue={{ id: "", title: "Type of Work" }}
                  selectedOption={getSelectedOption(services, filters.service)}
                />
              </div>
            )}

            {clientTypes && Array.isArray(clientTypes) && (
              <div className={s.inputContainer}>
                <Dropdown
                  options={clientTypes}
                  onChange={handleClientType}
                  defaultValue={{ id: "", title: "Type of Client" }}
                  selectedOption={getSelectedOption(clientTypes, filters.clientType)}
                />
              </div>
            )}

            <div className={cx(s.inputContainer, s.search)}>
              <div className={s.iconWrapper}>
                <img src={searchIcon} alt="search Icon" />
              </div>
              <label className={cx(s.label, { [s.hidden]: keyword })} htmlFor="search">
                Search work by client, type, keyword
              </label>

              <input
                className={s.searchInput}
                id="search"
                name="search"
                type="text"
                onChange={handleSearch}
                ref={searchInputRef}
                onFocus={handleFocus}
                onBlur={handleFocus}
                value={keyword}
                autoComplete="off"
              />
            </div>
          </form>
        </section>

        <section className={s.works} ref={projectsRef}>
          {!isWorksloading && (
            <>
              {works && works.length > 0 && Array.isArray(works) ? (
                <div className={s.worksList}>
                  {works.map((item, i) => {
                    return <WorkCard {...item} key={i} />
                  })}
                </div>
              ) : (
                <div className={s.notFound}>
                  <img src={notFound} alt="Not Found" />
                  <p className={s.notFoundText}>
                    Do you have a new project in mind?{" "}
                    <Link className={s.link} to="/contact" state={{ contactReason: "NEW_PROJECT" }}>
                      Reach out to us.
                    </Link>
                  </p>
                </div>
              )}
            </>
          )}
        </section>
      </main>
      <FooterMain />
    </>
  )
}

export default Works
