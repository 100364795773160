import s from "assets/scss/HamburgerMenu.module.scss"
import React, { useEffect, useState } from "react"

import cx from "classnames"

import { useMenuStore } from "store/menuStore"

const HamburgerMenu = () => {
  const menuStore = useMenuStore()
  const [isMenuActive, setIsMenuActive] = useState(false)

  // DISABLE MOUSE CLICK WHEN MENU ACTIVE
  useEffect(() => {
    setIsMenuActive(true)
    let timeoutId: ReturnType<typeof setTimeout>

    setTimeout(() => {
      setIsMenuActive(false)
    }, 1000)

    return () => {
      clearTimeout(timeoutId)
    }
  }, [menuStore.open])

  return (
    <div
      className={cx(s.xBurger, { [s.open]: menuStore.open, [s.disabled]: isMenuActive })}
      onClick={menuStore.toggle}
      data-hamburger-menu
    >
      <div className={s.xBurgerTextW}>
        <p className={s.xBurgerText}>MENU</p>
        <p className={s.xBurgerText}>CLOSE</p>
      </div>
      <div className={s.lines}>
        <div className={cx(s.line, s.horizontal1)}></div>
        <div className={cx(s.line, s.horizontal2)}></div>
        <div className={cx(s.line, s.horizontal3)}></div>

        <div className={s.cross1W}>
          <div className={s.cross1}></div>
        </div>

        <div className={s.cross2W}>
          <div className={s.cross2}></div>
        </div>
      </div>
    </div>
  )
}

export default React.memo(HamburgerMenu)
