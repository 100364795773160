import s from "assets/scss/Unsubscribe.module.scss"
import { FormEvent, useEffect, useRef, useState } from "react"

import cx from "classnames"

import api from "api"
import NotFound from "assets/img/smiley-upset.svg"
import axios from "axios"
import gsap from "gsap"
import { Link, useNavigate, useParams } from "react-router-dom"

const Unsubscribe = () => {
  const { email } = useParams()
  const screensRef = useRef<HTMLDivElement>(null)

  const navigate = useNavigate()

  const [resubscribed, setResubscribed] = useState(false)

  const formItems = [
    "I no longer want to receive these emails",
    "Emails are too frequent",
    "I never signed up to receive these emails",
    "Emails are inappropriate",
    "Emails are spam and should be reported",
  ]

  const [currentItem, setCurrentItem] = useState<number | null>(null)
  const [currentScreen, setCurrentScreen] = useState<"FORM_SCREEN" | "THANKS_SCREEN" | "ALREADY_SCREEN" | null>(null)

  const checkIsUnsubscribed = async () => {
    try {
      // 👇️ const data: CreateUserResponse
      const { data } = await api.post<any>("/home/isUnsubscribed.php", { email })
      return data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message)
        // 👇️ error: AxiosError<any, any>
        return error.message
      } else {
        console.log("unexpected error: ", error)
        return "An unexpected error occurred"
      }
    }
  }

  useEffect(() => {
    checkIsUnsubscribed().then((res: { success: string }) => {
      if (res.success) {
        handleScreenTransition("FORM_SCREEN")
      } else {
        handleScreenTransition("ALREADY_SCREEN")
      }
    })
  }, [])

  const unsubscribe = async () => {
    try {
      // 👇️ const data: CreateUserResponse
      const { data } = await api.post<any>("/home/unsubscribe.php", { email })
      return data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message)
        // 👇️ error: AxiosError<any, any>
        return error.message
      } else {
        console.log("unexpected error: ", error)
        return "An unexpected error occurred"
      }
    }
  }

  const handleSelect = (index: number) => {
    setCurrentItem(index)
  }

  const handleFormSubmit = (e: FormEvent) => {
    e.preventDefault()

    if (currentItem === null) {
      return
    }

    const submitForm = async () => {
      try {
        // 👇️ const data: CreateUserResponse
        const { data } = await api.post<any>("/home/unsubscribeReason.php", {
          email,
          unsubscribeReason: formItems[currentItem],
        })

        return data
      } catch (error) {
        if (axios.isAxiosError(error)) {
          console.log("error message: ", error.message)
          // 👇️ error: AxiosError<any, any>
          return error.message
        } else {
          console.log("unexpected error: ", error)
          return "An unexpected error occurred"
        }
      }
    }

    unsubscribe().then((res: { message: string; success: string }) => {
      if (res.success) {
        submitForm().then((res: { message: string; success: string }) => {
          if (res.success) {
            handleScreenTransition("THANKS_SCREEN")
          }
        })
      }
    })

    // handleScreenTransition("THANKS_SCREEN")
  }

  const handleUnsubscribe = () => {
    handleScreenTransition("FORM_SCREEN")
  }

  const handleResubscribe = () => {
    const resubscribe = async () => {
      try {
        // 👇️ const data: CreateUserResponse
        const { data } = await api.post<any>("/home/subscribe.php", { email })
        return data
      } catch (error) {
        if (axios.isAxiosError(error)) {
          console.log("error message: ", error.message)
          // 👇️ error: AxiosError<any, any>
          return error.message
        } else {
          console.log("unexpected error: ", error)
          return "An unexpected error occurred"
        }
      }
    }

    resubscribe().then((res: { message: string; success: string }) => {
      if (res.success) {
        setResubscribed(true)
        // handleScreenTransition("THANKS_SCREEN")
      }
    })

    // handleScreenTransition("THANKS_SCREEN")
  }

  const renderScreens = () => {
    switch (currentScreen) {
      case "THANKS_SCREEN":
        return (
          <div className={s.seeYou}>
            <h1>THANK YOU!</h1>

            <Link to="/">
              <button className={s.btn}>
                <p className={s.btnText}>HOMEPAGE</p>
              </button>
            </Link>

            <div>
              {resubscribed ? (
                <p className={s.resubscribedSuccessfully}>You have subscribed successfully!</p>
              ) : (
                <p className={s.resubscribe} onClick={handleResubscribe}>
                  Did you unsubscribe by mistake?{" "}
                  <span style={{ textDecoration: "underline", cursor: "pointer" }}>Resubscribe</span>
                </p>
              )}
            </div>
          </div>
        )
      case "ALREADY_SCREEN":
        return (
          <div className={s.alreadyUnsubscribed}>
            <p className={s.already}>
              You have already unsubscribed.{" "}
              <Link to="/" style={{ textDecoration: "underline", fontFamily: "VisbyCF-DemiBold" }}>
                Go to homepage
              </Link>
            </p>
          </div>
        )
      default:
        return (
          <div className={s.reason}>
            <h1>
              We’re sad to let you go… <br /> Hope to see you in <span>the future.</span>
            </h1>
            <p>Please let us know why you’re unsubscribing:</p>
            <form className={s.formWrapper} onSubmit={handleFormSubmit}>
              {formItems.map((item, index) => {
                return (
                  <div className={s.privacyCheck} key={index} onClick={() => handleSelect(index)}>
                    <div className={s.radioWrapper}>
                      <div
                        className={s.customRadio}
                        style={{
                          opacity: currentItem === index ? "1" : "0.6",
                          transition: "0.2s opacity ease",
                        }}
                      >
                        <div
                          className={s.radioCheck}
                          style={{
                            opacity: currentItem === index ? "1" : "0",
                            visibility: currentItem === index ? "visible" : "hidden",
                            transition: "0.2s opacity ease",
                          }}
                        ></div>
                      </div>
                      <div className={s.radioText}>
                        <p>{item}</p>
                      </div>
                    </div>
                  </div>
                )
              })}

              <button className={cx(s.btn, { [s.disabled]: currentItem === null })} type="submit">
                <p className={s.btnText}>SUBMIT</p>
              </button>
            </form>
          </div>
          // <div className={s.greeting}>
          //   <h1>DO YOU REALLY WANT TO UNSUBSCRIBE?</h1>
          //   <button className={s.btn}>
          //     <p onClick={handleUnsubscribe} className={s.btnText}>
          //       UNSUBSCRIBE
          //     </p>
          //   </button>
          // </div>
        )
    }
  }

  const handleScreenTransition = (screen: any) => {
    gsap.to(screensRef.current, {
      autoAlpha: 0,
      duration: 0.3,
      onComplete: () => {
        setCurrentScreen(screen)
        gsap.to(screensRef.current, {
          autoAlpha: 1,
          delay: 0.4,
          duration: 0.4,
        })
      },
    })
  }

  useEffect(() => {
    const hamburger = document.querySelector("[data-hamburger-menu]")

    gsap.set(hamburger, {
      autoAlpha: 0,
    })

    return () => {
      gsap.set(hamburger, {
        autoAlpha: 1,
      })
    }
  }, [])

  return (
    <div className={s.unsubscribeWrapper}>
      <div className={s.borderWrapper} ref={screensRef}>
        <img className={s.upset} src={NotFound} alt="Upset Smiley" />
        {renderScreens()}
      </div>
    </div>
  )
}

export default Unsubscribe
