import { create } from "zustand"

interface MenuState {
  open: boolean
  toggle: () => void
}

const useStore = create<MenuState>((set, get) => ({
  open: false,
  toggle: () => set({ open: !get().open }),
}))

export const useMenuStore = useStore
