import s from "assets/scss/About.module.scss"
import { useRef } from "react"

import cx from "classnames"
import { Helmet } from "react-helmet"
import { useQuery } from "react-query"
import { Link } from "react-router-dom"

import "swiper/css"
import "swiper/css/navigation"

import { ReactComponent as LogoBgood } from "assets/img/company-logos/logo-bgood.svg"
import { ReactComponent as LogoCitys } from "assets/img/company-logos/logo-citys.svg"
import { ReactComponent as LogoCookshop } from "assets/img/company-logos/logo-cookshop.svg"
import { ReactComponent as LogoGais } from "assets/img/company-logos/logo-gais.svg"
import { ReactComponent as LogoJustwork } from "assets/img/company-logos/logo-justwork.svg"
import { ReactComponent as LogoMado } from "assets/img/company-logos/logo-mado.svg"
import { ReactComponent as LogoMeydan } from "assets/img/company-logos/logo-meydan-istanbul.svg"
import { ReactComponent as LogoVoi } from "assets/img/company-logos/logo-voi.svg"
import { ReactComponent as LogoWam } from "assets/img/company-logos/logo-wam.svg"
import { ReactComponent as ConcaveRhombus } from "assets/img/concave-rhombus.svg"
import emojiBg from "assets/img/emoji-bg.png"
import linkedin from "assets/img/icon-linkedin.svg"

import { ReactComponent as DownArrow } from "assets/icon/icon-long-white-down-arrow.svg"
import allServices from "assets/img/all-services.svg"
import companies from "assets/img/companies.svg"
import referencesSun from "assets/img/references-sun.svg"
import { ReactComponent as Rocket } from "assets/img/rocket.svg"
import teamBlob from "assets/img/team-blob.png"
import { ReactComponent as TeamEyeUp } from "assets/img/team-eye-up.svg"
import teamSmile from "assets/img/team-smile.png"

import api from "api"
import { IAward, IService, ITeamMember } from "api/types"
import FooterMain from "components/FooterMain"
import MarqueeSimple from "components/MarqueeSimple"
import TeamSlider from "components/TeamSlider"
import { useWindowSize } from "hooks"
import { useCursorStore } from "store/cursorStore"
import { useFilterStore } from "store/filterStore"
import { breakpoints } from "variables"

const About = () => {
  const concaveRhombusRef = useRef(null)
  const marqueeText1 = useRef(null)
  const marqueeText2 = useRef(null)
  const teamEyeRef = useRef(null)
  const whatWeDoRef = useRef(null)
  const windowSize = useWindowSize()
  const { setService } = useFilterStore()
  const cursorStore = useCursorStore()

  const fetchServices = async () => {
    const res = await api.get("/about/servicesCards.php")
    return res.data
  }

  const fetchAwards = async () => {
    const res = await api.get("/about/awards.php")
    return res.data
  }

  const fetchTeamMembers = async () => {
    const res = await api.get("/about/team.php")
    return res.data
  }

  const { data: awards } = useQuery<IAward[], Error>(["awards"], fetchAwards, {
    enabled: true,
    retry: 2,
  })

  const { data: services } = useQuery<IService[], Error>(["servicesCards"], fetchServices, {
    enabled: true,
    retry: 2,
  })

  const {
    isLoading: isTeamMembersLoading,
    isSuccess,
    isError,
    data: teamMembers = ["", "", "", "", "", "", "", "", ""],
    error,
    refetch,
  } = useQuery<ITeamMember[], Error>(["team-members"], fetchTeamMembers, {
    enabled: true,
    retry: 2,
  })

  const renderRhombus = (arr: any, index: number) => {
    if (index % 2 === 1) {
      if (index < arr.length - 1) {
        return <ConcaveRhombus className={s.pin} ref={concaveRhombusRef} />
      } else {
        return
      }
    }
  }

  const saveFilter = (id: string) => {
    setService(id)
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>JUST DESIGN FX | About</title>
        <meta name="description" content="Learn about our design services, team members, clients and awards."></meta>
        <link rel="canonical" href="https://justdesignfx.com/about" />
      </Helmet>
      <main className={s.about}>
        <section className={s.intro}>
          <div className={s.titleWrapper}>
            <h1 className={s.title}>
              WE ARE <br />
              <span className={s.inlineJust}>just</span>
            </h1>
            <h1 className={s.title}>RELENTLESS</h1>
            <h1 className={s.title}>
              <span>dreamers.</span>
            </h1>
          </div>

          <div className={s.flyingTextWrapper}>
            <small>
              <span className={s.newLine}>
                We design iconic brands with future impact.<span className={s.emoji}>🚀</span>
              </span>
              JUST DESIGN FX
              <sup>®</sup> is a new generation awarded branding and web design agency that specializes in creating the
              best possible brand experience for business owners and target users.
            </small>
            <small>
              At JUST DESIGN FX<sup>®</sup>, we work collaboratively with clients from all over the world. Our working
              methodology involves analyzing a brand’s current position, understanding its design and marketing needs,
              and envisioning its best future.
              <span className={s.newLine}>Our offices are currently located in Istanbul and New York.</span>
            </small>
          </div>

          <div className={s.verticalTextLeft}>
            <img src={allServices} alt="A Full Services Creative Studio Text" />
          </div>

          <div className={s.verticalTextRight}>
            <h6>THE FUTURE IS NEAR.</h6>
            <DownArrow className={s.downArrow} />
          </div>

          <div className={s.downArrowMobile}>
            <DownArrow className={s.downArrow} />
          </div>

          <img
            className={s.teamBlob}
            src={teamBlob}
            alt="Water Blob"
            data-parallax
            data-speed-y="0.3"
            data-direction-y="-1"
          />

          <img
            className={s.teamSmile}
            src={teamSmile}
            alt="Smiley"
            data-parallax
            data-speed-y="0.2"
            data-direction-y="-1"
          />

          <div className={s.whatWeDo} ref={whatWeDoRef} data-parallax data-speed-y="0.6" data-direction-y="-1">
            <h1>
              WHAT
              <br /> WE DO.
            </h1>
          </div>
        </section>

        <section className={s.fields}>
          {services &&
            Array.isArray(services) &&
            services.map((service: any, i: number) => {
              return (
                <Link
                  onMouseDown={() => saveFilter(service.id)}
                  to="/works"
                  className={s.fieldCard}
                  key={service.id}
                  // target="_blank"
                  // rel="noopener noreferrer"
                >
                  <small className={s.index}>{`0${i + 1}.`}</small>
                  {service.title.split("<br>").map((word: string, i: number) => {
                    return (
                      <h5 className={s.title} key={i}>
                        {word}
                      </h5>
                    )
                  })}
                  <p className={s.description}>{service.description}</p>
                  <p className={s.seeAllWorks}>SEE ALL WORKS.</p>
                  {renderRhombus(services, i)}
                </Link>
              )
            })}
        </section>

        <section className={s.awards}>
          <h3 className={s.awardsTitle}>
            AWARDS
            {/* <span className={s.s}>S</span> */}
          </h3>

          <div className={s.table}>
            <div className={cx(s.row, s.title)}>
              <h5 className={s.text}>Award</h5>
              <h5 className={s.text}>Project & Category</h5>
            </div>

            {awards &&
              awards.length > 0 &&
              Array.isArray(awards) &&
              awards.map((award) => {
                return (
                  <>
                    {award.url ? (
                      <a href={award.url} target="_blank" rel="noreferrer" key={award.id}>
                        <div className={cx(s.row, s.item)} key={award.id}>
                          <img className={s.trophy} src={award.image} alt="Trophy" />
                          <h5 className={s.text}>{award.title}</h5>
                          <h5 className={s.text}>{award.category}</h5>
                          {award.url && <h5 className={s.link}>View Case</h5>}
                        </div>
                      </a>
                    ) : (
                      <div key={award.id}>
                        <div className={cx(s.row, s.item)} key={award.id}>
                          <img className={s.trophy} src={award.image} alt="Trophy" />
                          <h5 className={s.text}>{award.title}</h5>
                          <h5 className={s.text}>{award.category}</h5>
                        </div>
                      </div>
                    )}
                  </>
                )
              })}
          </div>

          <div className={s.tableMobile}>
            {awards &&
              awards.length > 0 &&
              Array.isArray(awards) &&
              awards?.map((award) => {
                return (
                  <>
                    {award.url ? (
                      <a href={award.url} target="_blank" rel="noreferrer" key={award.id}>
                        <img className={s.trophy} src={award.image} alt="Trophy" />
                        <div className={cx(s.row, s.title)}>
                          <h5 className={s.text}>Award</h5>
                          <h5 className={s.text}>{award.title}</h5>
                        </div>
                        <div className={cx(s.row, s.item)}>
                          <h5 className={s.text}>Project & Category</h5>
                          <h5 className={s.text}>{award.category}</h5>
                        </div>
                        <div className={cx(s.row, s.item)}>
                          <div>{/* dummy */}</div>
                          <h5 className={s.link}>View Case</h5>
                        </div>
                      </a>
                    ) : (
                      <div key={award.id}>
                        <img className={s.trophy} src={award.image} alt="Trophy" />
                        <div className={cx(s.row, s.title)}>
                          <h5 className={s.text}>Award</h5>
                          <h5 className={s.text}>{award.title}</h5>
                        </div>
                        <div className={cx(s.row, s.item)}>
                          <h5 className={s.text}>Project & Category</h5>
                          <h5 className={s.text}>{award.category}</h5>
                        </div>
                      </div>
                    )}
                  </>
                )
              })}

            {/* <img className={s.pentawardsLogo} src={pentawards} alt="Pentawards Logo" /> */}
          </div>
        </section>

        <section className={s.references}>
          <div className={s.textWrapper}>
            <h3>SOME OF</h3>
            <h3>OUR</h3>
            <h3>
              <span>clients...</span>
            </h3>
          </div>

          <img className={s.referencesSun} src={referencesSun} alt="Sun Smiley" />
          <img className={s.companies} src={companies} alt="Company Logos" />

          <div className={s.companiesMobile}>
            <div className={s.logoWrapper}>
              <LogoWam className={s.companyLogo} />
            </div>
            <div className={s.logoWrapper}>
              <LogoGais className={s.companyLogo} />
            </div>
            <div className={s.logoWrapper}>
              <LogoMeydan className={s.companyLogo} />
            </div>
            <div className={s.logoWrapper}>
              <LogoVoi className={s.companyLogo} />
            </div>
            <div className={s.logoWrapper}>
              <LogoMado className={s.companyLogo} />
            </div>
            <div className={s.logoWrapper}>
              <LogoCitys className={s.companyLogo} />
            </div>
            <div className={s.logoWrapper}>
              <LogoBgood className={s.companyLogo} />
            </div>
            <div className={s.logoWrapper}>
              <LogoJustwork className={s.companyLogo} />
            </div>
            <div className={s.logoWrapper}>
              <LogoCookshop className={s.companyLogo} />
            </div>
          </div>
        </section>

        <section className={s.marqueeSection}>
          <div className={cx(s.mWrapper, s.white)}>
            {/* <Marquee>
              <h1 ref={marqueeText1} className={s.marqueeText}>
                <span className={s.rightMargin}>
                  OUR TEAM OF CREAT<span className={s.inlineO}>O</span>RS. OUR TEAM OF CREAT
                  <span className={s.inlineO}>O</span>RS.
                </span>
              </h1>
            </Marquee> */}
            <MarqueeSimple>
              <h1 ref={marqueeText1} className={s.marqueeText}>
                <span className={s.rightMargin}>
                  OUR TEAM OF CREAT<span className={s.inlineO}>O</span>RS. OUR TEAM OF CREAT
                  <span className={s.inlineO}>O</span>RS.
                </span>
              </h1>
            </MarqueeSimple>
          </div>

          <div className={cx(s.mWrapper, s.gray)}>
            {/* <Marquee>
              <h1 ref={marqueeText2} className={s.marqueeText}>
                <span className={s.rightMargin}>
                  OUR TEAM OF CREAT<span className={s.inlineO}>O</span>RS. OUR TEAM OF CREAT
                  <span className={s.inlineO}>O</span>RS.
                </span>
              </h1>
            </Marquee> */}
            <MarqueeSimple direction={-1} speed={0.5}>
              <h1 ref={marqueeText2} className={s.marqueeText}>
                <span className={s.rightMargin}>
                  OUR TEAM OF CREAT<span className={s.inlineO}>O</span>RS. OUR TEAM OF CREAT
                  <span className={s.inlineO}>O</span>RS.
                </span>
              </h1>
            </MarqueeSimple>
          </div>

          <div className={s.eyeWrapper}>
            <TeamEyeUp ref={teamEyeRef} className={s.teamEye} data-flip />
            <small className={s.eyeText}>CHECK OUT OUR TEAM</small>
          </div>

          <div className={s.rocketWrapper}>
            <Rocket className={s.rocket} />
          </div>
        </section>

        <section className={s.teamMembersDesktop}>
          <div className={s.horizontalScroll} data-h-scroll>
            <div className={s.progressBar} data-hscroll-scrollbar></div>
            {teamMembers &&
              teamMembers.length > 0 &&
              teamMembers.map((member: any, i: number) => {
                return (
                  <div data-h-scroll-section className={cx(s.horizontalScrollItem, s.slide1)} key={i}>
                    <div className={s.memberCard}>
                      <div className={s.imgWrapper}>
                        <div className={s.emojiWrapper}>
                          <img src={member.iconImage} className={s.emoji} alt="Team Member" />
                          <img className={s.emojiBg} src={emojiBg} alt="Member Emoji" />
                        </div>
                        <img src={member.profileImage} className={s.memberPhoto} alt="Team Member" />
                      </div>
                      <div
                        className={s.info}
                        style={{
                          paddingRight:
                            i === teamMembers.length - 1 && windowSize.width > breakpoints.tablet ? "25vw" : undefined,
                        }}
                      >
                        <h6 className={s.credentials}>
                          {member.name}
                          <br /> {member.surname}
                        </h6>
                        {member?.role?.split("<br>").map((par: string, i: number) => {
                          return (
                            <small className={s.role} key={i}>
                              {par}
                            </small>
                          )
                        })}

                        {member.linkedin && (
                          <a href={member.linkedin} target="_blank" rel="noreferrer">
                            <img className={s.linkedinLogo} src={linkedin} alt="Linkedin Logo" />
                          </a>
                        )}
                      </div>
                    </div>
                  </div>
                )
              })}

            {/* {window.innerWidth > 640 && (
                    <div className={s.fixedElement} ref={fixedElRef}>
                      <img ref={arrowDownRef} className={s.arrowDown} src={arrowDown} alt="Down Arrow Icon" />
                      <p>KEEP SCROOOOOOOOOOLLING!</p>
                    </div>
                  )}

                  <div className={s.progressBar} ref={progressRef}></div> */}
          </div>
        </section>

        {windowSize.width <= breakpoints.tablet && <TeamSlider items={teamMembers}></TeamSlider>}
      </main>
      <FooterMain />
    </>
  )
}

export default About
