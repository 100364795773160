import React, { useEffect, useRef, useState } from "react"
import s from "assets/scss/PreloaderProgress.module.scss"

const PreloaderProgress = ({ up }: any) => {
  const progressWrapperRef = useRef(null)

  const [progress, setProgress] = useState("")

  useEffect(() => {
    let img: any, c: number, tot: number

    img = document.images
    c = 0
    tot = img.length

    const imgLoaded = () => {
      c += 1
      setProgress((((100 / tot) * c) << 0) + "%")

      if (c === tot) return up()
      if (c === tot) return
    }

    const counter = () => {
      for (var i = 0; i < tot; i++) {
        var tImg = new Image()
        tImg.onload = imgLoaded
        tImg.onerror = imgLoaded
        tImg.src = img[i].src
      }
    }

    counter()

    return () => {}
  }, [])

  return (
    <div className={s.progressWrapper} ref={progressWrapperRef}>
      <div className={s.progress}>
        <h5 style={{ fontFamily: "VanguardCF-Bold" }}>{progress}</h5>
      </div>
    </div>
  )
}

export default PreloaderProgress
