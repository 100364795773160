import { useEffect, useRef } from "react"
import videojs from "video.js"

// Styles
import "video.js/dist/video-js.css"

interface IVideoPlayerProps {
  options?: videojs.PlayerOptions
  onReady?: () => {}
}

const initialOptions: videojs.PlayerOptions = {
  controls: true,
  fluid: true,
  playsinline: true,
  controlBar: {
    volumePanel: {
      inline: false,
    },
  },
}

const VideoPlayer: React.FC<IVideoPlayerProps> = ({ options, onReady }) => {
  const videoNode = useRef<HTMLVideoElement>(null)
  const player = useRef<videojs.Player | null>(null)

  useEffect(() => {
    if (videoNode.current) {
      player.current = videojs(videoNode.current, {
        ...initialOptions,
        ...options,
      }).ready(function () {
        // console.log('onPlayerReady', this);
      })
    }

    return () => {
      if (player.current) {
        player.current.dispose()
      }
    }
  }, [options])

  return <video ref={videoNode} className="video-js" />
}

export default VideoPlayer
