import s from "assets/scss/MediaContainer.module.scss"

import React, { useRef, useState } from "react"
import cx from "classnames"

import playIcon from "assets/img/play-btn.svg"

import { IMediaData } from "api/types"
import { useWindowSize } from "hooks"
import { useAllMediaStore } from "store/allMediaStore"
import { useModalStore } from "store/modalStore"
import { baseUrl, breakpoints, env } from "variables"
import AllMediaSlider from "./AllMediaSlider"
import Img from "./Img"
import Vid from "./Vid"

const VideoWithControls = (item: any) => {
  const size = useWindowSize()
  const videoRef = useRef<HTMLVideoElement>(null)
  const [isPlaying, setIsPlaying] = useState<boolean>(false)

  const handleVideo = () => {
    if (videoRef.current === null) {
      return
    }

    if (videoRef.current.onplaying) {
      setIsPlaying(false)
      videoRef.current.pause()
    } else if (videoRef.current.paused) {
      setIsPlaying(true)
      videoRef.current.play()
    } else {
      setIsPlaying(true)
      videoRef.current.play()
    }
  }

  return (
    <>
      <div className={cx(s.iconWrapper, { [s.hidden]: isPlaying })}>
        <img className={s.play} src={playIcon} alt="Play Icon" />
      </div>
      <img
        onClick={handleVideo}
        className={cx(s.thumbnail, { [s.hidden]: isPlaying })}
        src={
          size.width <= breakpoints.mobile
            ? item.mediaData.mediaMobile.thumbnail
            : item.mediaData.mediaDesktop.thumbnail
        }
        alt="Video Thumbnail"
      />
      <video ref={videoRef} className={s.video} playsInline controls>
        <source src={item.mediaData.mediaDesktop.url} type="video/mp4" />
      </video>
    </>
  )
}

const MediaContainer = (props: IMediaData) => {
  const size = useWindowSize()
  const modalStore = useModalStore()
  const windowSize = useWindowSize()
  const allMediaStore = useAllMediaStore()

  const handleAllMediaSlider = (selected: string) => {
    if (allMediaStore.content.length <= 0) return

    function check(item: any) {
      return item.mediaData.mediaDesktop.url.includes(selected)
    }

    const selectedIndex = allMediaStore.content.findIndex(check)

    modalStore.setContent(
      <div className={s.sliderWrapper}>
        <AllMediaSlider items={[...allMediaStore.content]} activeSlide={selectedIndex} title={allMediaStore.title} />
      </div>
    )
    modalStore.toggle()
  }

  return (
    <div className={cx(s.mediaContainer, { [s.paddingX]: props.isPaddingX })}>
      {props.items.length > 0 &&
        Array.isArray(props.items) &&
        props.items.map((item, i) => {
          return (
            <React.Fragment key={i}>
              {item.mediaType === "video" ? (
                <div className={s.mediaWrapper} style={{ width: `${100 / props.items.length}%` }}>
                  {item.isAutoplay ? (
                    <>
                      <div className={s.vidC} onClick={() => handleAllMediaSlider(item.mediaData.mediaDesktop.url)}>
                        <Vid
                          src={
                            size.width <= breakpoints.mobile
                              ? item.mediaData.mediaMobile.url
                              : item.mediaData.mediaDesktop.url
                          }
                          width={
                            size.width <= breakpoints.mobile
                              ? item.mediaData.mediaMobile.width
                              : item.mediaData.mediaDesktop.width
                          }
                          height={
                            size.width <= breakpoints.mobile
                              ? item.mediaData.mediaMobile.height
                              : item.mediaData.mediaDesktop.height
                          }
                          lazy
                          bgColor="#dedede"
                        ></Vid>
                      </div>
                      {windowSize.width > breakpoints.tablet && (
                        <div
                          className={s.sliderBtn}
                          onClick={() => {
                            handleAllMediaSlider(item.mediaData.mediaDesktop.url)
                          }}
                        >
                          Enlarge
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      <VideoWithControls
                        {...item}
                        src={
                          size.width <= breakpoints.mobile
                            ? item.mediaData.mediaMobile.url
                            : item.mediaData.mediaDesktop.url
                        }
                      />
                      <div
                        className={s.sliderBtn}
                        onClick={() => {
                          if (windowSize.width > breakpoints.tablet) {
                            handleAllMediaSlider(item.mediaData.mediaDesktop.url)
                          }
                        }}
                      >
                        Enlarge
                      </div>
                    </>
                  )}
                </div>
              ) : (
                <div
                  className={s.mediaWrapper}
                  style={{ width: `${100 / props.items.length}%` }}
                  onClick={() => handleAllMediaSlider(item.mediaData.mediaDesktop.url)}
                >
                  <Img
                    src={`${baseUrl[env]}/jd-admin/assets/images/projects/${props.workUrl}/${
                      size.width <= breakpoints.mobile
                        ? item.mediaData.mediaMobile.url
                        : item.mediaData.mediaDesktop.url
                    }`}
                    width={
                      size.width <= breakpoints.mobile
                        ? item.mediaData.mediaMobile.width
                        : item.mediaData.mediaDesktop.width
                    }
                    height={
                      size.width <= breakpoints.mobile
                        ? item.mediaData.mediaMobile.height
                        : item.mediaData.mediaDesktop.height
                    }
                    alt="Commercial Product Visuals"
                    lazy
                    bgColor="#dedede"
                  />
                  <div className={s.sliderBtn}>Enlarge</div>
                </div>
              )}
            </React.Fragment>
          )
        })}
    </div>
  )
}

export default MediaContainer
