import s from "assets/scss/Marquee.module.scss"
import { Fragment, ReactElement } from "react"

type Props = {
  children: ReactElement
  direction?: number
}

const Marquee = ({ children, direction = 1 }: Props) => {
  return (
    <div className={s.marquee} data-marquee>
      <div className={s.marqueeInner} data-marquee-sliding data-direction={direction}>
        {Array.from(Array(10)).map((item, i) => {
          return <Fragment key={i}>{children}</Fragment>
        })}
      </div>
    </div>
  )
}

export default Marquee
