import s from "assets/scss/FooterContact.module.scss"

import cx from "classnames"

import Copyright from "components/Copyright"
import IconArrowSquare from "components/Icons/IconArrowSquare"

const FooterContact = () => {
  return (
    <div className={s.revealWrapper} data-reveal>
      <div className={s.overlay} data-overlay></div>
      <div className={s.contactFooter}>
        <div className={s.contact}>
          <div className={s.col}>
            <h5 className={cx(s.colTitle, s.addressTitle)}>ISTANBUL HQ</h5>
            <p className={cx(s.colText, s.textSm)}>
              JUSTWork Office Campus Balkan Cad. <br />
              No:62, 34770, ISTANBUL / TURKEY
            </p>
            <div className={s.buttons}>
              <a
                className={s.btnGetDirection}
                target="_blank"
                rel="noreferrer"
                href="https://www.google.com/maps/place/JUSTWork/@41.0234497,29.1265974,15z/data=!4m5!3m4!1s0x0:0x5af99b7e2004cb13!8m2!3d41.0234497!4d29.1265974"
              >
                <p className={s.btnText}>GET DIRECTION</p>
                <div className={s.arrowW}>
                  <IconArrowSquare></IconArrowSquare>{" "}
                </div>
              </a>
            </div>
          </div>

          <div className={s.col}>
            <h5 className={cx(s.colTitle, s.addressTitle)}>NEW YORK HQ</h5>
            <p className={cx(s.colText, s.textSm)}>
              44 W 47th St #22, New York, <br />
              NY 10036 USA
            </p>
            <div className={s.buttons}>
              <a
                className={s.btnGetDirection}
                target="_blank"
                rel="noreferrer"
                href="https://www.google.com/maps/place/44+W+47th+St+%2322,+New+York,+NY+10036,+USA/@40.757209,-73.9807804,17z/data=!3m1!4b1!4m5!3m4!1s0x89c258ffa808b8d3:0x4aa58f11c530b90a!8m2!3d40.757209!4d-73.9807804"
              >
                <p className={s.btnText}>GET DIRECTION</p>
                <div className={s.arrowW}>
                  <IconArrowSquare></IconArrowSquare>
                </div>
              </a>
            </div>
          </div>

          <div className={s.col}>
            <h5 className={s.colTitle}>JOIN THE TEAM</h5>
            <p className={s.colText}>
              Send us your CV, we are always looking
              <br /> for new talents.
            </p>
            <div className={s.buttons}>
              <a className={s.btn} href="mailto:career@justdesignfx.com">
                <p className={s.btnText}>CAREER</p>
              </a>
            </div>
          </div>

          {/* <div className="col">
        <h5>FOLLOW US</h5>
        <div className="social">
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.instagram.com/justdesignfx/"
          >
            <img src={instagram} alt="Instagram Logo" />
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.behance.net/justdesign_fx"
          >
            <img src={behance} alt="Behance Logo" />
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://tr.linkedin.com/company/just-design-fx"
          >
            <img src={linkedin} alt="Linkedin Logo" />
          </a>
        </div>
      </div> */}
        </div>

        <Copyright />
      </div>
    </div>
  )
}

export default FooterContact
