import s from "assets/scss/WorkCard.module.scss"

import { useState } from "react"
import { Link } from "react-router-dom"

import { IWorkCard } from "api/types"
import { useWindowSize } from "hooks"
import { useCursorStore } from "store/cursorStore"
import { breakpoints } from "variables"
import Img from "./Img"
import Vid from "./Vid"

const WorkCard = (props: IWorkCard) => {
  const [isLoaded, setIsLoaded] = useState(false)
  const windowSize = useWindowSize()
  const cursorStore = useCursorStore()

  return (
    <Link to={`/works/${props.url}`} className={s.card}>
      <div
        className={s.mediaContainer}
        {...(windowSize.width > breakpoints.tablet && {
          onMouseEnter: () => {
            cursorStore.toggleText()
          },
        })}
        {...(windowSize.width > breakpoints.tablet && {
          onMouseLeave: () => {
            cursorStore.toggleText()
            cursorStore.toggleDefault()
          },
        })}
      >
        {props.mediaType === "video" ? (
          <video
            onLoadedData={() => {
              setIsLoaded(true)
            }}
            style={{ opacity: isLoaded ? 1 : 0, transition: "0.4s all ease" }}
            className={s.video}
            src={props.mediaSrcDesktop}
            autoPlay
            playsInline
            loop
            muted
          ></video>
        ) : (
          // <div className={s.video}>
          //   <Vid src={props.mediaSrcMobile} autoPlay loop muted lazy />
          // </div>
          <>
            <img
              onLoad={() => {
                setIsLoaded(true)
              }}
              style={{ opacity: isLoaded ? 1 : 0, transition: "0.4s all ease" }}
              className={s.image}
              src={props.mediaSrcDesktop}
              alt="Project Visual"
            />
            {/* <div className={s.image}>
              <Img src={props.mediaSrcMobile} alt="Project Visual" lazy />
            </div> */}
            {props.awardImage && <img className={s.award} src={props.awardImage} alt="Award Visual" />}
          </>
        )}
      </div>

      <h3 className={s.title}>{props.title}</h3>
    </Link>
  )
}

export default WorkCard
