import { IMediaData } from "api/types"
import s from "assets/scss/ImageReveal.module.scss"
import gsap from "gsap"
import { useWindowSize } from "hooks"
import { useRef, useState } from "react"
import { baseUrl, breakpoints, env } from "variables"
import Img from "./Img"

const ImageReveal = (props: IMediaData) => {
  const slidingImageRef = useRef(null)
  const imageWrapperRef = useRef<HTMLDivElement>(null)
  const size = useWindowSize()

  const [skewAmt, setSkewAmt] = useState(0)

  function handleMouseMove(e: any) {
    if (imageWrapperRef.current) {
      let skewAmount: number = 0
      skewAmount = gsap.utils.mapRange(0, imageWrapperRef.current.clientWidth, 0, 100, e.clientX)
      setSkewAmt(skewAmount - 9.5)
    }
  }

  return (
    <div className={s.imageRevealWrapper}>
      <div
        className={s.imageWrapper}
        ref={imageWrapperRef}
        {...(size.width > breakpoints.tablet && { onMouseMove: handleMouseMove })}
      >
        <div className={s.bgImg}>
          <Img
            src={`${baseUrl[env]}/jd-admin/assets/images/projects/${props.workUrl}/${
              size.width > breakpoints.mobile
                ? props.items[0].mediaData.mediaDesktop.url
                : props.items[0].mediaData.mediaMobile.url
            }`}
            alt="Commercial Product Visuals"
            width={
              size.width <= breakpoints.mobile
                ? props.items[0].mediaData.mediaMobile.width
                : props.items[0].mediaData.mediaDesktop.width
            }
            height={
              size.width <= breakpoints.mobile
                ? props.items[0].mediaData.mediaMobile.height
                : props.items[0].mediaData.mediaDesktop.height
            }
            lazy
          />
        </div>
        <div
          className={s.sImageWrapper}
          ref={slidingImageRef}
          style={{
            clipPath: `polygon(0 0, ${skewAmt}% 0, ${skewAmt}% 100%, 0% 100%)`,
          }}
        >
          {size.width > breakpoints.mobile && (
            <div className={s.imgWrapper}>
              <Img
                src={`${baseUrl[env]}/jd-admin/assets/images/projects/${props.workUrl}/${
                  size.width > breakpoints.mobile
                    ? props.items[1].mediaData.mediaDesktop.url
                    : props.items[1].mediaData.mediaMobile.url
                }`}
                alt="Commercial Product Visuals"
                width={
                  size.width <= breakpoints.mobile
                    ? props.items[1].mediaData.mediaMobile.width
                    : props.items[1].mediaData.mediaDesktop.width
                }
                height={
                  size.height <= breakpoints.mobile
                    ? props.items[1].mediaData.mediaMobile.height
                    : props.items[1].mediaData.mediaDesktop.height
                }
                lazy
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default ImageReveal
