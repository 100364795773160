import s from "assets/scss/StickyOtherWorks.module.scss"

import { Link } from "react-router-dom"

import otherWorks from "assets/img/other-works.svg"

const StickyOtherWorks = () => {
  return (
    <Link className={s.scrollTracker} to="/works" data-sticky-other-works>
      <div className={s.orange}></div>
      <img className={s.otherWorks} src={otherWorks} alt="Other Works Text Around Eyes" />
    </Link>
  )
}

export default StickyOtherWorks
