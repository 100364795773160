import WithReactQuery from "hocs/WithReactQuery"
import React from "react"
import ReactDOM from "react-dom/client"
import { BrowserRouter as Router } from "react-router-dom"
import App from "./App"
import "./index.scss"

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
root.render(
  <React.StrictMode>
    <WithReactQuery>
      <Router>
        <App />
      </Router>
    </WithReactQuery>
  </React.StrictMode>
)
