import s from "assets/scss/Header.module.scss"

import cx from "classnames"
import { useRef } from "react"
import { Link } from "react-router-dom"

import logoHeader from "assets/img/jdfx-logo-black.svg"
import { useThemeStore } from "store/themeStore"

const Header = () => {
  const jdfxLogoRef = useRef(null)

  const themeStore = useThemeStore()

  return (
    <>
      <header className={cx(s.header, { [s.light]: themeStore.theme === "LIGHT" })}>
        <Link to="/">
          <img ref={jdfxLogoRef} className={s.jdfxLogo} src={logoHeader} alt="JUSTDesign FX Logo" />
        </Link>
      </header>
    </>
  )
}

export default Header
