import { create } from "zustand"

interface CursorState {
  default: boolean
  visible: boolean
  hidden: boolean
  texted: boolean
  award: boolean
  slide: boolean
  toggleHide: () => void
  toggleVisibility: () => void
  toggleText: () => void
  toggleAward: () => void
  toggleDefault: () => void
  toggleSlide: () => void
}

const useStore = create<CursorState>((set, get) => ({
  default: true,
  visible: false,
  scaled: false,
  hidden: false,
  circled: false,
  texted: false,
  award: false,
  slide: false,
  toggleDefault: () =>
    set(() => ({
      default: !get().default,
      seeMore: false,
      texted: false,
      hidden: false,
      slide: false,
    })),
  toggleHide: () =>
    set(() => ({
      hidden: !get().hidden,
      seeMore: false,
      texted: false,
    })),
  toggleSlide: () =>
    set(() => ({
      slide: !get().slide,
      seeMore: false,
    })),
  toggleVisibility: () => set(() => ({ visible: !get().visible })),
  toggleText: () =>
    set(() => ({
      texted: !get().texted,
      default: !get().default,
    })),
  toggleAward: () =>
    set(() => ({
      award: !get().award,
      default: !get().default,
    })),
}))

export const useCursorStore = useStore
