import s from "assets/scss/TeamSlider.module.scss"
import React, { useState } from "react"

import cx from "classnames"

// import Swiper core and required modules
import { Navigation } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"

import { ReactComponent as RightArrow } from "assets/icon/icon-white-right-arrow.svg"

// Import Swiper styles
import { useSwiperRef } from "hooks"

import "swiper/css"
import "swiper/css/navigation"

import linkedin from "assets/img/icon-linkedin.svg"
import emojiBg from "assets/img/emoji-bg.png"

// import { ITeamMember } from "api/types"

type Props = {
  items: any
}

const TeamSlider = ({ items }: Props) => {
  const [currentSlide, setCurrentSlide] = useState(0)
  const [nextEl, nextElRef] = useSwiperRef<HTMLButtonElement>()
  const [prevEl, prevElRef] = useSwiperRef<HTMLButtonElement>()

  return (
    <div className={s.teamSlider}>
      <Swiper
        style={{ width: "100%", height: "100%" }}
        modules={[Navigation]}
        onSlideChange={(e: any) => setCurrentSlide(e.activeIndex)}
        slidesPerView={1}
        breakpoints={{
          // when window width is >= 640px
          641: {
            spaceBetween: 50,
            slidesPerView: 1.2,
          },
        }}
        spaceBetween={0}
        speed={600}
        navigation={{
          prevEl,
          nextEl,
          disabledClass: "hidden",
        }}
      >
        {Array.isArray(items) &&
          items.map((item, i) => {
            return (
              <React.Fragment key={item.id}>
                <SwiperSlide>
                  <div className={s.memberCard}>
                    <div className={s.imgWrapper}>
                      <div className={s.emojiWrapper}>
                        <img src={item.iconImage} className={s.emoji} alt="Team Member" />
                        <img className={s.emojiBg} src={emojiBg} alt="Member Emoji" />
                      </div>
                      <img src={item.profileImage} className={s.memberPhoto} alt="Team Member" />
                    </div>
                    <div className={s.info}>
                      <h6 className={s.credentials}>
                        {item.name}
                        <br /> {item.surname}
                      </h6>
                      {item.role?.split("<br>").map((par: string, i: number) => {
                        return (
                          <small className={s.role} key={i}>
                            {par}
                          </small>
                        )
                      })}
                      <a href={item.linkedin} target="_blank" rel="noreferrer">
                        <img className={s.linkedinLogo} src={linkedin} alt="Linkedin Logo" />
                      </a>
                    </div>
                  </div>
                </SwiperSlide>
              </React.Fragment>
            )
          })}
      </Swiper>

      <button className={cx(s.btnWrapper, s.btnPrev, { [s.disabled]: currentSlide === 0 })} ref={prevElRef}>
        <div className={s.btnInner}>
          <RightArrow className={cx(s.btnArrow, s.prev)} />
        </div>
      </button>

      <button
        className={cx(s.btnWrapper, s.btnNext, { [s.disabled]: currentSlide === items.length - 1 })}
        ref={nextElRef}
      >
        <div className={s.btnInner}>
          <RightArrow className={cx(s.btnArrow, s.next)} />
        </div>
      </button>
    </div>
  )
}

export default TeamSlider
