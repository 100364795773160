import { create } from "zustand"

interface LoadingState {
  active: boolean
  toggle: () => void
}

const useStore = create<LoadingState>((set, get) => ({
  active: true,
  toggle: () => set({ active: !get().active }),
}))

export const useLoadingStore = useStore
