import s from "assets/scss/Home.module.scss"

import cx from "classnames"
import { useWindowSize } from "hooks"
import { useQuery } from "react-query"
import { Link } from "react-router-dom"

import homeBlob from "assets/img/home-blob.png"
import jdSmileUp from "assets/img/jd-smile-looking-up.png"
import playIcon from "assets/img/play-btn.svg"
import seeAllArrow from "assets/img/see-all-arrow.svg"

import { IProjectCard } from "api/types"
import FooterMain from "components/FooterMain"
import Marquee from "components/Marquee"
import VideoPlayer from "components/VideoPlayer"
import ProjectCardHome from "components/WorkCardHome"
import { useEffect, useRef } from "react"
import { useLoadingStore } from "store/loadingStore"
import { useModalStore } from "store/modalStore"
import { breakpoints } from "variables"
import api from "api"
import MarqueeSimple from "components/MarqueeSimple"
import { Helmet } from "react-helmet"

const Home = () => {
  const windowSize = useWindowSize()
  const modalStore = useModalStore()
  const loadingStore = useLoadingStore()
  const showreelRef = useRef<HTMLVideoElement>(null)

  const fetchProjects = async () => {
    const res = await api.get("/home/latestWorks.php")
    return res.data
  }

  const { data: featuredWorks } = useQuery<IProjectCard[], Error>(["projects"], fetchProjects, {
    enabled: true,
    retry: 2,
  })

  const modalVideoOptions = {
    autoplay: true,
    loop: false,
    muted: false,
    controls: true,
    responsive: true,
    playsinline: true,
    sources: [
      {
        src: "https://player.vimeo.com/progressive_redirect/playback/714020513/rendition/1080p/file.mp4?loc=external&signature=2bd17e7a1706fa74d450d209a071c8f41a9a81e14e9c5b2edab639634f69b780",
        type: "video/mp4",
      },
    ],
  }

  const toggleModal = () => {
    modalStore.setContent(
      <div className={s.modalContent}>
        <VideoPlayer options={modalVideoOptions} />
      </div>
    )
    modalStore.toggle()
  }

  useEffect(() => {
    if (!showreelRef.current) {
      return
    }

    if (modalStore.open) {
      showreelRef.current.pause()
    } else {
      showreelRef.current.play()
    }
  }, [modalStore.open])

  // START VIDEO WHEN LOADING
  useEffect(() => {
    if (showreelRef.current) {
      if (loadingStore.active) {
        showreelRef.current.pause()
      } else {
        showreelRef.current.play()
      }
    }
  }, [loadingStore.active])

  return (
    <>
      {/* <Helmet>
        <meta charSet="utf-8" />
        <title>JUST DESIGN FX | Branding, Packaging, and Web Design</title>
        <meta
          name="description"
          content="We design iconic brands with future impact. JUST DESIGN FX is a future-focused brand and digital design agency with offices located in Istanbul and New York."
        ></meta>
        <link rel="canonical" href="https://justdesignfx.com/" />
      </Helmet> */}
      <main className={s.home}>
        <section className={s.intro}>
          <div className={s.imgContainer} data-parallax data-speed-y="0.7" data-direction-y="-1">
            <img src={homeBlob} alt="Blob" className={s.img} />
          </div>

          <div className={s.textWrapper}>
            <h6 className={cx(s.small, s.lookAhead)}>LOOK AHEAD</h6>
            <h1 className={s.title}>
              THE<span className={s.oblique}> future</span>
            </h1>
            <h1 className={cx(s.title, s.hasArrived)}>HAS ARRIVED.</h1>
            <div className={s.textBottom}>
              <h6 className={s.small}>AWARDED BRANDING & WEB DESIGN AGENCY.</h6>
            </div>
          </div>

          <div
            className={s.videoContainer}
            {...(windowSize.width > breakpoints.tabletMini && { onClick: toggleModal })}
          >
            <video ref={showreelRef} className={s.video} loop muted playsInline>
              <source
                src={
                  "https://player.vimeo.com/progressive_redirect/playback/735759197/rendition/1080p/file.mp4?loc=external&signature=e30cfa5b1167a21b92162ecb0fb71a006e86aa05246f9052bad228a6baebbff3"
                }
                type="video/mp4"
              />
            </video>
            {windowSize.width < breakpoints.tablet && (
              <button
                className={s.playShowreelBtnTouch}
                onClick={() => {
                  toggleModal()
                }}
              >
                <p className={s.btnText}>PLAY SHOWREEL</p>
                <div className={s.iconRightArrow}>
                  <img className={s.arrow} src={playIcon} alt="Play Icon" />
                </div>
              </button>
            )}
          </div>
        </section>

        <section className={s.works}>
          <div className={s.marqueeContainer}>
            {windowSize.width <= breakpoints.tablet ? (
              <MarqueeSimple>
                <div className={s.marqueeInner}>
                  <span className={s.rightMargin}>LATEST</span>
                  <span className={s.rightMargin}>
                    W<span className={s.inlineO}>O</span>RKS.
                  </span>
                </div>
              </MarqueeSimple>
            ) : (
              <Marquee direction={-1}>
                <div className={s.marqueeInner}>
                  <span className={s.rightMargin}>LATEST</span>
                  <span className={s.rightMargin}>
                    W<span className={s.inlineO}>O</span>RKS.
                  </span>
                </div>
              </Marquee>
            )}
          </div>

          <div className={s.projects}>
            {featuredWorks?.length !== 0 &&
              Array.isArray(featuredWorks) &&
              featuredWorks?.map((work, i: number) => {
                return <ProjectCardHome {...work} key={i} />
              })}
          </div>

          <div className={s.bottomTextWrapper}>
            <h1 className={s.firstLine}>
              TAKE
              <span className={s.inlineALook}>
                <span className={s.inlineA}>a</span>l<span className={s.inlineOo}>oo</span>k
              </span>
              AT
            </h1>
            <h1 className={s.secondLine}>
              WHAT WE'VE D<span className={s.inlineO}>O</span>NE
            </h1>
            <h1 className={s.thirdLine}>SO FAR...</h1>
          </div>

          <div className={s.jdSmileyContainer}>
            <Link className={s.seeAllWorkBtnC} to="/works">
              <button className={s.seeAllWorkBtn}>
                <p className={s.btnText}> SEE ALL WORK</p>
              </button>
              <div className={s.bg}></div>
            </Link>
            <div className={s.arrowContainer}>
              <img className={s.arrow} src={seeAllArrow} alt="Arrow" />
            </div>
            <div className={s.smileyContainer}>
              <img
                className={s.jdSmiley}
                src={jdSmileUp}
                alt="Smiley Looking Sky"
                data-parallax
                data-speed-y="0.3"
                data-direction-y="-1"
              />
            </div>
          </div>
        </section>
      </main>
      <FooterMain />
    </>
  )
}

export default Home
