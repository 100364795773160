import s from "assets/scss/WorkInfo.module.scss"
import { useEffect, useRef, useState } from "react"

import cx from "classnames"

import { IWorkInfo } from "api/types"
import rightArrow from "assets/icon/icon-white-right-arrow.svg"
import pentrophy from "assets/img/pentawards-trophy.png"
import IconArrowSquare from "components/Icons/IconArrowSquare"
import { useWindowSize } from "hooks"
import { useModalStore } from "store/modalStore"
import { useThemeStore } from "store/themeStore"
import { breakpoints } from "variables"
import Img from "./Img"
import Vid from "./Vid"

const WorkInfo = (props: IWorkInfo) => {
  const windowSize = useWindowSize()
  const modalStore = useModalStore()

  const [textExpanded, setTextExpanded] = useState(false)

  const descTextWrapperRef = useRef(null)
  const wrInnerRef = useRef<HTMLDivElement>(null)

  const [mHeight, setMHeight] = useState<number | null>(null)
  const [animateText, setAnimateText] = useState(false)

  const expand = () => {
    setTextExpanded((prevState) => !prevState)
  }

  const handleExpand = () => {
    setAnimateText((prevState) => !prevState)
    expand()
  }

  useEffect(() => {
    const descTextWrapperHeight = wrInnerRef?.current?.clientHeight
    descTextWrapperHeight && setMHeight(descTextWrapperHeight)
  }, [])

  const modalContent = (
    <div className={s.modalContent}>
      <div className={s.imgW}>
        {props.mediaType === "video" ? (
          <video
            className={s.projectVid}
            src={windowSize.width > breakpoints.mobile ? props.mediaSrc : props.mediaSrcMobile}
            autoPlay
            playsInline
            loop
            muted
          ></video>
        ) : (
          // <Img src={props.mediaSrc} alt="Project Cover Image"></Img>
          <img
            loading="eager"
            className={s.img}
            src={windowSize.width > breakpoints.mobile ? props.mediaSrc : props.mediaSrcMobile}
            alt="Project Banner"
          ></img>
        )}
      </div>
      <div className={s.info}>
        <h2 className={s.descTitle}>THE CASE</h2>
        <div
          className={s.descText}
          ref={descTextWrapperRef}
          style={{
            maxHeight: `${animateText ? mHeight : windowSize.width * 0.6}`,
          }}
        >
          {props.case.detail && (
            <div className={s.wrInner} ref={wrInnerRef} dangerouslySetInnerHTML={{ __html: props.case.detail }}></div>
          )}
        </div>
      </div>
    </div>
  )

  const handleModal = () => {
    if (windowSize.width < breakpoints.tablet) {
      return
    }
    modalStore.setContent(modalContent)
    modalStore.toggle()
  }

  const themeStore = useThemeStore()

  useEffect(() => {
    themeStore.setTheme("LIGHT")

    return () => {
      themeStore.setTheme("DARK")
    }
  }, [])

  return (
    <>
      <section className={s.workInfo}>
        <div className={s.mediaWrapper}>
          {props.mediaType === "video" ? (
            <div className={s.projectVid}>
              <Vid
                src={windowSize.width > breakpoints.mobile ? props.mediaSrc : props.mediaSrcMobile}
                lazy
                autoPlay
                loop
                muted
              ></Vid>
            </div>
          ) : (
            <>
              <Img
                src={windowSize.width > breakpoints.mobile ? props.mediaSrc : props.mediaSrcMobile}
                alt="Project Visual"
                lazy
              />
              {props.awardImage && <img className={s.award} src={props.awardImage} alt="Award Visual" />}
            </>
          )}
        </div>

        <div className={s.details}>
          <div className={s.title}>
            <small>PROJECT</small>
            <h1>
              <span className={s.company}>
                {props.title} <br />
              </span>
              {props.service && <span className={s.seperator}> | </span>}
              <span className={s.service}>{props.service}</span>
            </h1>
          </div>

          <div className={s.text}>
            <div className={s.left}>
              <div className={s.col}>
                {props.client && (
                  <div className={s.item}>
                    <h5 className={s.infoTitle}>CLIENT</h5>
                    <p className={s.par}>{props.client}</p>
                  </div>
                )}

                {props.clientType && (
                  <div className={s.item}>
                    <h5 className={s.infoTitle}>CLIENT TYPE</h5>
                    {props.clientType.map((p: string, i: number) => {
                      return (
                        <p className={s.par} key={i}>
                          {p}
                        </p>
                      )
                    })}
                  </div>
                )}

                {props.award && (
                  <div className={s.item}>
                    <h5 className={s.infoTitle}>
                      AWARDS
                      <span>
                        <img className={s.trophy} src={pentrophy} alt="Trophy" />
                      </span>
                    </h5>
                    {props.award.map((award: any) => {
                      return (
                        <>
                          {award.ui.split("<br>").map((splitted: string, i: number) => {
                            return (
                              <a
                                href={award.url}
                                target="_blank"
                                rel="noreferrer"
                                className={cx(s.par, s.awards)}
                                key={i}
                              >
                                {splitted}
                              </a>
                            )
                          })}
                        </>
                      )
                    })}
                  </div>
                )}

                {props.media && (
                  <div className={s.item}>
                    <h5 className={s.infoTitle}>MEDIA</h5>
                    {props.media?.length > 0 &&
                      props.media.map((media: any, i: number) => {
                        return (
                          <p className={s.par} key={i}>
                            <a href={media.url} target="_blank" rel="noreferrer" className={cx(s.par, s.link)}>
                              {media.ui}
                            </a>
                          </p>
                        )
                      })}
                  </div>
                )}
              </div>

              <div className={s.col}>
                {props.market && (
                  <div className={s.item}>
                    <h5 className={s.infoTitle}>MARKET</h5>
                    <p className={s.par}>{props.market}</p>
                  </div>
                )}

                {props.website && (
                  <div className={s.item}>
                    <h5 className={s.infoTitle}>WEBSITE</h5>
                    <a href={props.website?.url} target="_blank" rel="noreferrer" className={cx(s.par, s.link)}>
                      {props.website.ui}
                    </a>
                  </div>
                )}
              </div>

              <div className={s.col}>
                {props.services && (
                  <div className={s.item}>
                    <h5 className={s.infoTitle}>SERVICES</h5>
                    {props.services.map((p: string, i: number) => {
                      return (
                        <p className={s.par} key={i}>
                          {p}
                        </p>
                      )
                    })}
                  </div>
                )}
              </div>
            </div>

            <div className={s.description}>
              <h2 className={s.descTitle}>THE CASE</h2>
              <div
                className={cx(s.descTextWrapper, { [s.expanded]: textExpanded })}
                style={{
                  maxHeight: `${animateText ? mHeight : window.innerWidth * 0.73}px`,
                }}
                ref={descTextWrapperRef}
              >
                <div className={s.wrInner} ref={wrInnerRef}>
                  {windowSize.width > breakpoints.mobile ? (
                    <>
                      {props.case.summary && (
                        <p className={s.descText} dangerouslySetInnerHTML={{ __html: props.case.summary }}></p>
                      )}
                    </>
                  ) : (
                    <>
                      {props.case.detail ? (
                        <p className={s.descText} dangerouslySetInnerHTML={{ __html: props.case.detail }}></p>
                      ) : (
                        <p className={s.descText} dangerouslySetInnerHTML={{ __html: props.case.summary }}></p>
                      )}
                    </>
                  )}
                  <div className={s.press}>
                    {props.case.mediaLink && <div dangerouslySetInnerHTML={{ __html: props.case.mediaLink }}></div>}
                  </div>
                </div>
              </div>

              {props.case.detail && (
                <button className={s.btnReadMore} onClick={handleModal}>
                  <p className={s.btnText}>READ MORE</p>
                  <div className={s.arrowW}>
                    <IconArrowSquare></IconArrowSquare>
                  </div>
                </button>
              )}

              {windowSize.width < breakpoints.mobile && (
                <button onClick={handleExpand} className={s.viewMoreBtnTouch}>
                  <p>{textExpanded ? "CLOSE" : "READ MORE"}</p>
                  <div
                    className={s.iconRightArrow}
                    style={{
                      transition: "1.2s all ease",
                      transform: textExpanded ? "rotate(-45deg)" : "rotate(135deg)",
                    }}
                  >
                    <img className={s.arrow} src={rightArrow} alt="Arrow" />
                  </div>
                </button>
              )}
            </div>
          </div>

          {/* <div className={s.marquee-info}>
            <InfiniteMarqueeV2 speed={60}>
              <div className={s.m-wrapper}>
                <div className={s.span-wrapper}>
                  <span className={s.right-margin}>*</span>
                  <span className={s.right-margin}>THE</span>
                  <span className={s.right-margin}>CASE</span>
                  <span className={s.right-margin}>*</span>
                  <span className={s.right-margin}>THE</span>
                  <span className={s.right-margin}>CASE</span>
                </div>
              </div>
            </InfiniteMarqueeV2>
          </div> */}
        </div>
      </section>
    </>
  )
}

export default WorkInfo
