export default function IconArrowSquare({ fill = "#f2f2f2" }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="9" height="9" viewBox="0 0 9 9">
      <path
        id="Path_5163"
        data-name="Path 5163"
        d="M273.6,453.737h8.634a.186.186,0,0,0,.2-.167v-8.633a.214.214,0,0,0-.2-.2h-1.9V450.2l-5.467-5.429-1.433,1.468,5.467,5.428h-5.467v1.9a.158.158,0,0,0,.167.167Z"
        transform="translate(-444.737 282.433) rotate(-90)"
        fill={fill}
        fillRule="evenodd"
      />
    </svg>
  )
}
