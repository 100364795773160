import { IMediaData } from "api/types"
import { create } from "zustand"

interface AllMediaState {
  content: IMediaData[]
  title: string
  setContent: (content: IMediaData[]) => void
  setTitle: (title: string) => void
}

const useStore = create<AllMediaState>((set, get) => ({
  content: [],
  title: "",
  setContent: (content) => set({ content: [...content] }),
  setTitle: (title) => set({ title }),
}))

export const useAllMediaStore = useStore
