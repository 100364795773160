import s from "assets/scss/Vid.module.scss"
import { useEffect } from "react"

import cx from "classnames"
import LazyLoad from "vanilla-lazyload"

const conf = { elements_selector: ".lazy" }

// Only initialize it one time for the entire application
if (!document.hasOwnProperty("lazyLoadInstance")) {
  // @ts-ignore: Unreachable code error
  document.lazyLoadInstance = new LazyLoad(conf)
}

type Props = {
  src?: string
  lazy?: boolean
  muted?: boolean
  loop?: boolean
  autoPlay?: boolean
  width?: number
  height?: number
  bgColor?: string
}

const Vid = ({
  src = "#",
  lazy = false,
  muted = true,
  loop = true,
  autoPlay = true,
  height = 500,
  width = 500,
  bgColor = "transparent",
}: Props) => {
  useEffect(() => {
    // @ts-ignore: Unreachable code error
    document.lazyLoadInstance.update()
  })

  return (
    <figure className={s.figure} style={{ background: bgColor }}>
      <div
        className={s.aspectRatio}
        style={{ "--aspectRatio": `${(height / width) * 100}%` } as React.CSSProperties}
      ></div>
      {lazy ? (
        <video className={cx(s.vid, "lazy")} muted={muted} autoPlay={autoPlay} loop={loop} playsInline>
          <source data-src={src} type="video/mp4" />
        </video>
      ) : (
        <video className={s.vid} muted={muted} autoPlay={autoPlay} loop={loop} playsInline>
          <source src={src} type="video/mp4" />
        </video>
      )}
    </figure>
  )
}

export default Vid
