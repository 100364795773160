export const breakpoints = {
  mobile: 640,
  tabletMini: 768,
  tablet: 1024,
  desktop: 1440,
  widescreen: 1920,
}

interface IBaseUrl {
  prod: string
  dev: string
}

export const baseUrl: IBaseUrl = {
  prod: "https://justdesignfx.com",
  dev: "https://demo.justdesignfx.com",
}

export const env = "prod"
