import s from "assets/scss/Preloader.module.scss"

import cx from "classnames"
import gsap, { Power1, Power2, Power3 } from "gsap"
import Lottie from "lottie-react"
import { useEffect, useRef } from "react"

import logoBlack from "assets/img/jdfx-logo-black.svg"
import jdfxLottie from "assets/lottie/jdfx-logo-lottie.json"
import useWindowSize from "hooks/useWindowSize"
import { useLoadingStore } from "store/loadingStore"
import PreloaderProgress from "./PreloaderProgress"

const Preloader = () => {
  const lsWrapperRef = useRef<HTMLDivElement>(null)
  const oHiddenWrapperRef = useRef(null)

  const oh1Ref = useRef(null)
  const oh2Ref = useRef(null)
  const oh3Ref = useRef(null)

  const dummyNavMenu1Ref = useRef(null)
  const dummyNavMenu2Ref = useRef(null)
  const dummyNavMenu3Ref = useRef(null)

  const jdfxMenuLogoRef = useRef(null)

  const linksWrapper1Ref = useRef(null)
  const linksWrapper2Ref = useRef(null)
  const linksWrapper3Ref = useRef(null)

  const loadingMenuTL = useRef<any>(null)

  const windowSize = useWindowSize()
  const loadingStore = useLoadingStore()

  useEffect(() => {
    gsap.set(document.querySelector(".scroll-content"), {
      opacity: 0,
    })

    dummyNavMenu1Ref.current &&
      gsap.set(dummyNavMenu1Ref.current, {
        xPercent: -33,
        duration: 1,
        ease: Power2.easeInOut,
      })

    dummyNavMenu2Ref.current &&
      gsap.set(dummyNavMenu2Ref.current, {
        xPercent: -66,
        duration: 1,
        ease: Power2.easeInOut,
      })

    dummyNavMenu3Ref.current &&
      gsap.set(dummyNavMenu3Ref.current, {
        xPercent: -99,
        duration: 1,
        ease: Power2.easeInOut,
      })

    oh1Ref.current &&
      oh2Ref.current &&
      oh3Ref.current &&
      gsap.set([oh1Ref.current, oh2Ref.current, oh3Ref.current], {
        width: 0,
      })

    jdfxMenuLogoRef.current &&
      gsap.set(jdfxMenuLogoRef.current, {
        rotation: 0,
        xPercent: 0,
      })

    const animationDuration = 1

    loadingMenuTL.current = gsap
      .timeline({
        paused: true,
        yoyo: true,
        repeat: 1,
        repeatDelay: 0.5,
        delay: 0.5,
      })
      .add("start")
      .to(
        oh1Ref.current,
        {
          width: "34%",
          duration: animationDuration,
          ease: Power3.easeInOut,
        },
        "start"
      )
      .to(
        oh2Ref.current,
        {
          width: "34%",
          duration: animationDuration,
          ease: Power3.easeInOut,
        },
        "start"
      )
      .to(
        oh3Ref.current,
        {
          width: "34%",
          duration: animationDuration,
          ease: Power3.easeInOut,
        },
        "start"
      )
      .to(
        dummyNavMenu1Ref.current,
        {
          xPercent: 0,
          duration: animationDuration,
          ease: Power1.easeInOut,
        },
        "start"
      )
      .to(
        dummyNavMenu2Ref.current,
        {
          xPercent: -33,
          duration: animationDuration,
          ease: Power1.easeInOut,
        },
        "start"
      )
      .to(
        dummyNavMenu3Ref.current,
        {
          xPercent: -66,
          duration: animationDuration,
          ease: Power1.easeInOut,
        },
        "start"
      )
      .from(
        jdfxMenuLogoRef.current,
        {
          rotation: -360,
          xPercent: -500,
          duration: animationDuration,
          ease: Power2.easeInOut,
          onComplete: () => {
            gsap.to(document.querySelector(".scroll-content"), {
              opacity: 1,
              delay: 1.3,
              duration: 0.4,
            })
            gsap.to(lsWrapperRef.current, {
              autoAlpha: 0,
              duration: 0,
            })
          },
        },
        "start"
      )

    return () => {
      loadingMenuTL.current.kill()
      loadingMenuTL.current.clear()
    }
  }, [])

  const animateMenu = () => {
    loadingMenuTL.current.play().then(() => {
      loadingStore.toggle()
    })
  }

  const up = () => {
    animateMenu()
  }

  return (
    <>
      {loadingStore.active && (
        <>
          <div className={s.lsWrapper} ref={lsWrapperRef} style={{ height: windowSize.height }} onClick={up}>
            <Lottie
              rendererSettings={{
                preserveAspectRatio: "xMidYMid slice",
              }}
              autoplay={true}
              animationData={jdfxLottie}
              className={s.logoLottie}
            />
            <div className={s.progressV2} style={{ visibility: "hidden" }}>
              <PreloaderProgress up={up} />
            </div>
          </div>

          <div className={s.loadingOHiddenWrapper} ref={oHiddenWrapperRef} style={{ height: windowSize.height }}>
            <div className={s.oHidden1} ref={oh1Ref}>
              <div className={cx(s.lDummyNavMenu, s.dnm1)} ref={dummyNavMenu1Ref}>
                <div className={s.textWrapper} ref={linksWrapper1Ref}>
                  <img ref={jdfxMenuLogoRef} className={s.jdfxLogoMenu} src={logoBlack} alt="JUST DESIGN FX Logo" />
                  <h1>FUTURE</h1>
                  <h1>EXPERIENCE.</h1>
                </div>
              </div>
            </div>
            <div className={s.oHidden2} ref={oh2Ref}>
              <div className={cx(s.lDummyNavMenu, s.dnm3)} ref={dummyNavMenu2Ref}>
                <div className={s.textWrapper} ref={linksWrapper2Ref}>
                  <h1>FUTURE</h1>
                  <h1>EXPERIENCE.</h1>
                </div>
              </div>
            </div>
            <div className={s.oHidden3} ref={oh3Ref}>
              <div className={cx(s.lDummyNavMenu, s.dnm3)} ref={dummyNavMenu3Ref}>
                <div className={s.textWrapper} ref={linksWrapper3Ref}>
                  <h1>FUTURE</h1>
                  <h1>EXPERIENCE.</h1>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default Preloader
