import s from "assets/scss/NotFound.module.scss"
// import { useEffect } from "react"

import notFound from "assets/img/not-found.svg"
import { Helmet } from "react-helmet"

const NotFound = () => {
  // useEffect(() => {
  //   window.location.href = "https://justdesignfx.com/404.php"
  // }, [])

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <div className={s.notFoundPage}>
        <img src={notFound} alt="404 Error Text With Upset Smiley" />
        <h1>Page Not Found</h1>
        <p>The Page you are looking for doesn't exist or an other error occured.</p>
      </div>
    </>
  )
}

export default NotFound
