import React, { useEffect, useRef } from "react"
import s from "assets/scss/MagnetCursor.module.scss"
import useMousePosition from "hooks/useMousePosition"

import { ReactComponent as CursorPentawards } from "assets/icon/pentawards.svg"

import cx from "classnames"
import gsap from "gsap"
import { useCursorStore } from "store/cursorStore"

const MagnetCursor = () => {
  const cursorDefaultRef = useRef(null)
  const magnetCursorRef = useRef(null)
  const cursorStore = useCursorStore()
  const { clientX, clientY } = useMousePosition()

  // control screen display
  useEffect(() => {
    const handleMouseEnter = () => {
      if (!cursorStore.visible) cursorStore.toggleVisibility()
    }

    const handleMouseLeave = () => {
      if (cursorStore.visible) cursorStore.toggleVisibility()
    }

    document.body.addEventListener("mouseenter", handleMouseEnter)
    document.body.addEventListener("mouseleave", handleMouseLeave)

    return () => {
      document.body.removeEventListener("mouseenter", handleMouseEnter)
      document.body.removeEventListener("mouseleave", handleMouseLeave)
    }
  }, [cursorStore.visible])

  useEffect(() => {
    if (cursorStore.visible) {
      magnetCursorRef.current &&
        gsap.to(magnetCursorRef.current, {
          x: () => clientX - 10,
          y: () => clientY - 5,
          duration: 0,
        })
    }
  }, [clientX, clientY, cursorStore.visible])

  return (
    <div
      className={cx(s.magnetCursor, {
        [s.visible]: cursorStore.visible,
      })}
      ref={magnetCursorRef}
    >
      <div className={s.cursor} ref={cursorDefaultRef}>
        <div className={cx(s.inner, { [s.active]: cursorStore.texted || cursorStore.award })}>
          <p
            className={cx(s.texted, {
              [s.active]: cursorStore.texted && !cursorStore.award,
            })}
          >
            <span className={s.text}>VIEW</span>
          </p>
          <p
            className={cx(s.award, {
              [s.active]: cursorStore.award && !cursorStore.texted,
            })}
          >
            <CursorPentawards />
          </p>
        </div>
        <div
          className={cx(s.default, {
            [s.active]: !cursorStore.texted && !cursorStore.award,
            [s.default]: cursorStore.default,
          })}
        ></div>
      </div>
    </div>
  )
}

export default MagnetCursor
