import s from "assets/scss/OtherWorks.module.scss"

import cx from "classnames"

// import Swiper core and required modules
import { Navigation } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"

import { ReactComponent as RightArrow } from "assets/icon/icon-white-right-arrow.svg"

// Import Swiper styles
import { useSwiperRef, useWindowSize } from "hooks"
import { useState } from "react"

import "swiper/css"
import "swiper/css/navigation"

import { Link } from "react-router-dom"
import { useCursorStore } from "store/cursorStore"
import { breakpoints } from "variables"
import Img from "./Img"
import Vid from "./Vid"

const OtherWorks = ({ works }: any) => {
  const [currentSlide, setCurrentSlide] = useState(0)
  const [nextEl, nextElRef] = useSwiperRef<HTMLButtonElement>()
  const [prevEl, prevElRef] = useSwiperRef<HTMLButtonElement>()

  const size = useWindowSize()
  const cursorStore = useCursorStore()

  return (
    <div className={s.otherWorks}>
      <Swiper
        style={{ width: "100%", height: "100%" }}
        modules={[Navigation]}
        onSlideChange={(e: any) => setCurrentSlide(e.activeIndex)}
        slidesPerView={1}
        loop={true}
        breakpoints={{
          // when window width is >= 640px
          641: {
            spaceBetween: 50,
            slidesPerView: 1.2,
          },
        }}
        spaceBetween={0}
        speed={600}
        navigation={{
          prevEl,
          nextEl,
          disabledClass: "hidden",
        }}
      >
        {Array.isArray(works) &&
          works.map((item, i) => {
            return (
              <SwiperSlide key={item.id}>
                <Link className={s.hoverW} to={`/works/${item.url}`}>
                  <div className={s.sliderItem}>
                    <div
                      className={s.mediaWrapper}
                      {...(size.width > breakpoints.tablet && {
                        onMouseEnter: () => {
                          cursorStore.toggleText()
                        },
                      })}
                      {...(size.width > breakpoints.tablet && {
                        onMouseLeave: () => {
                          cursorStore.toggleText()
                          cursorStore.toggleDefault()
                        },
                      })}
                    >
                      {item.mediaType === "video" ? (
                        // <video className={s.media} src={size.width > breakpoints.mobile ? item.mediaSrcDesktop : item.mediaSrcMobile} autoPlay playsInline loop muted></video>
                        <Vid
                          src={size.width > breakpoints.mobile ? item.mediaSrcDesktop : item.mediaSrcMobile}
                          lazy
                          bgColor="#dedede"
                        ></Vid>
                      ) : (
                        <>
                          {/* <img className={s.media} src={size.width > breakpoints.mobile ? item.mediaSrcDesktop : item.mediaSrcMobile} alt="Project Visual" /> */}
                          <Img
                            src={size.width > breakpoints.mobile ? item.mediaSrcDesktop : item.mediaSrcMobile}
                            alt="Project Cover"
                            lazy
                            bgColor="#dedede"
                          ></Img>
                          {item.awardImage && <img className="award" src={item.awardImage} alt="Award Visual" />}
                        </>
                      )}
                    </div>

                    <div className={s.info}>
                      <div className={s.cardTagWrapper}>
                        {item.services &&
                          item.services.map((service: string, index: number) => {
                            return (
                              <small key={index} className={s.cardTag}>
                                {service}
                              </small>
                            )
                          })}
                      </div>
                      <h5 className={s.title}>{item.title}</h5>
                      {/* {item.summary && (
                        <div className={s.text} dangerouslySetInnerHTML={{ __html: item.summary }}></div>
                      )} */}
                      <div className={s.cardBtn}>
                        <p className={s.btnText}>SEE FULL WORK</p>
                      </div>
                    </div>
                  </div>
                </Link>
              </SwiperSlide>
            )
          })}
      </Swiper>

      <button className={cx(s.btnWrapper, s.btnPrev, { [s.disabled]: currentSlide === 0 })} ref={prevElRef}>
        <div className={s.btnInner}>
          <RightArrow className={cx(s.btnArrow, s.prev)} />
        </div>
      </button>

      <button
        className={cx(s.btnWrapper, s.btnNext, { [s.disabled]: currentSlide === works.length - 1 })}
        ref={nextElRef}
      >
        <div className={s.btnInner}>
          <RightArrow className={cx(s.btnArrow, s.next)} />
        </div>
      </button>
    </div>
  )
}

export default OtherWorks
