import { create } from "zustand"
import { persist, createJSONStorage } from "zustand/middleware"

interface FilterState {
  filters: {
    service: string
    clientType: string
  }
  setService: (val: string) => void
  setClientType: (val: string) => void
  resetFilters: () => void
}

const useStore = create<FilterState>()(
  persist(
    (set, get) => ({
      filters: { service: "", clientType: "" },
      setService: (val) => set({ filters: { ...get().filters, service: val } }),
      setClientType: (val) => set({ filters: { ...get().filters, clientType: val } }),
      resetFilters: () => set({ filters: { ...get().filters, clientType: "", service: "" } }),
    }),
    {
      name: "filters",
      storage: createJSONStorage(() => localStorage),
    }
  )
)

export const useFilterStore = useStore
