import { create } from "zustand"

interface ScrollLockState {
  locked: boolean
  toggle: () => void
}

const useStore = create<ScrollLockState>((set, get) => ({
  locked: false,
  toggle: () => set({ locked: !get().locked }),
}))

export const useScrollLockStore = useStore
