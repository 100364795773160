import axios from "axios"
import { baseUrl, env } from "variables"

const api = axios.create({
  baseURL: `${baseUrl[env]}/jd-admin/services`,
  headers: {
    "Content-type": "application/json",
  },
})

export default api
