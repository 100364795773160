import s from "assets/scss/Menu.module.scss"
import React, { useEffect, useRef, useState } from "react"

import cx from "classnames"
import { Elastic, gsap, Power2 } from "gsap"
import { Link, useLocation } from "react-router-dom"

import { useMenuStore } from "store/menuStore"

import chooseWisely from "assets/img/choose-wisely.svg"
import contactSvg from "assets/img/contact.svg"
import logoMenu from "assets/img/jdfx-logo-menu.svg"
import aboutSvg from "assets/img/about.svg"
import worksSvg from "assets/img/works.svg"

import { SmoothContext } from "hocs/WithSmooth"
import { useLockedBody } from "hooks"
import useWindowSize from "hooks/useWindowSize"
import { useScrollLockStore } from "store/scrollLockStore"
import { breakpoints } from "variables"

const Menu = () => {
  // Nav Menu
  const navMenuRef = useRef(null)
  const teamRef = useRef(null)
  const teamRef1 = useRef(null)
  const teamRef2 = useRef(null)
  const teamRef3 = useRef(null)
  const worksRef = useRef(null)
  const worksRef1 = useRef(null)
  const worksRef2 = useRef(null)
  const worksRef3 = useRef(null)
  const contactRef = useRef(null)
  const contactRef1 = useRef(null)
  const contactRef2 = useRef(null)
  const contactRef3 = useRef(null)

  const dummyNavMenuRef1 = useRef(null)
  const dummyNavMenuRef2 = useRef(null)
  const dummyNavMenuRef3 = useRef(null)

  const linksWrapperRef = useRef(null)
  const linksWrapper1Ref = useRef(null)
  const linksWrapper2Ref = useRef(null)
  const linksWrapper3Ref = useRef(null)

  const oHiddenWrapperRef = useRef(null)

  const jdfxMenuLogo1Ref = useRef(null)
  const jdfxMenuLogo2Ref = useRef(null)

  const oh1Ref = useRef(null)
  const oh2Ref = useRef(null)
  const oh3Ref = useRef(null)

  const [oldLink, setOldLink] = useState<"about" | "works" | "contact" | null>(null)
  const [currentLink, setCurrentLink] = useState<"about" | "works" | "contact" | null>(null)

  const menuStore = useMenuStore()
  const windowSize = useWindowSize()

  const [_, setLocked] = useLockedBody(false, "root")
  const sContext = React.useContext(SmoothContext)

  useEffect(() => {
    const initialScaleX = 1
    const initialScaleY = 1
    const scaleXAmount = 1.05
    const scaleYAmount = 2.2
    const shrinkAmount = 0.5
    const tDuration = 1.2
    const easeFunc = Elastic.easeOut

    switch (currentLink) {
      case "about":
        gsap.to([teamRef.current, teamRef1.current, teamRef2.current, teamRef3.current], {
          scaleY: scaleYAmount,
          scaleX: scaleXAmount,
          ease: easeFunc,
          duration: tDuration,
        })
        gsap.to([worksRef.current, worksRef1.current, worksRef2.current, worksRef3.current], {
          scaleY: shrinkAmount,
          scaleX: initialScaleX,
          yPercent: 85,
          ease: easeFunc,
          duration: tDuration,
        })
        gsap.to([contactRef.current, contactRef1.current, contactRef2.current, contactRef3.current], {
          scaleY: shrinkAmount,
          scaleX: initialScaleX,
          ease: easeFunc,
          duration: tDuration,
        })
        break
      case "works":
        gsap.to([teamRef.current, teamRef1.current, teamRef2.current, teamRef3.current], {
          scaleY: () => (currentLink === "works" ? 0.5 : 1),
          scaleX: initialScaleX,
          ease: easeFunc,
          duration: tDuration,
        })
        gsap.to([contactRef.current, contactRef1.current, contactRef2.current, contactRef3.current], {
          scaleY: () => (currentLink === "works" ? 0.5 : 1),
          scaleX: initialScaleX,
          ease: easeFunc,
          duration: tDuration,
        })
        gsap.to([worksRef.current, worksRef1.current, worksRef2.current, worksRef3.current], {
          scaleY: scaleYAmount,
          scaleX: scaleXAmount,
          yPercent: 0,
          ease: easeFunc,
          duration: tDuration,
        })
        break
      case "contact":
        gsap.to([teamRef.current, teamRef1.current, teamRef2.current, teamRef3.current], {
          scaleY: shrinkAmount,
          scaleX: initialScaleX,
          ease: easeFunc,
          duration: tDuration,
        })
        gsap.to([worksRef.current, worksRef1.current, worksRef2.current, worksRef3.current], {
          scaleY: shrinkAmount,
          yPercent: -85,
          ease: easeFunc,
          duration: tDuration,
        })
        gsap.to([contactRef.current, contactRef1.current, contactRef2.current, contactRef3.current], {
          scaleY: scaleYAmount,
          scaleX: scaleXAmount,
          ease: easeFunc,
          duration: tDuration,
        })
        break
      default:
        gsap.to([teamRef.current, teamRef1.current, teamRef2.current, teamRef3.current], {
          scaleY: initialScaleY,
          scaleX: initialScaleX,
          ease: easeFunc,
          duration: tDuration,
        })
        gsap.to([worksRef.current, worksRef1.current, worksRef2.current, worksRef3.current], {
          scaleY: initialScaleY,
          scaleX: initialScaleX,
          yPercent: 0,
          ease: easeFunc,
          duration: tDuration,
        })
        gsap.to([contactRef.current, contactRef1.current, contactRef2.current, contactRef3.current], {
          scaleY: initialScaleY,
          scaleX: initialScaleX,
          ease: easeFunc,
          duration: tDuration,
        })
    }
  }, [currentLink, oldLink])

  // Timelines
  const navMenuTL = useRef<any>(null)

  const animateF = () => {
    setOldLink(currentLink)
    setCurrentLink("about")
  }

  const animateF2 = () => {
    setOldLink(currentLink)
    setCurrentLink("works")
  }

  const animateF3 = () => {
    setOldLink(currentLink)
    setCurrentLink("contact")
  }

  const animateR = () => {
    setCurrentLink(null)
  }

  useEffect(() => {
    gsap.set([teamRef.current, teamRef1.current, teamRef2.current, teamRef3.current], {
      transformOrigin: "0 0",
    })

    gsap.set([worksRef.current, worksRef1.current, worksRef2.current, worksRef3.current], {
      transformOrigin: "0 50%",
    })

    gsap.set([contactRef.current, contactRef1.current, contactRef2.current, contactRef3.current], {
      transformOrigin: "0 100%",
    })

    gsap.set(dummyNavMenuRef1.current, {
      xPercent: 0,
    })

    gsap.set(dummyNavMenuRef2.current, {
      xPercent: -33,
    })

    gsap.set(dummyNavMenuRef3.current, {
      xPercent: -66,
    })

    gsap.set(navMenuRef.current, {
      display: "none",
      duration: 0,
      opacity: 0,
    })

    gsap.set(oh1Ref.current, {
      width: "33%",
    })

    gsap.set(oh2Ref.current, {
      width: "33%",
    })

    gsap.set(oh3Ref.current, {
      width: "34%",
    })

    gsap.set([jdfxMenuLogo1Ref.current, jdfxMenuLogo2Ref.current], {
      rotation: 0,
      xPercent: 0,
    })

    navMenuTL.current = gsap
      .timeline({ paused: true })
      .add("s")
      .to(
        [oh1Ref.current, oh2Ref.current, oh3Ref.current],
        {
          width: 0,
          duration: 1,
          ease: Power2.easeInOut,
        },
        "s"
      )
      .to(
        dummyNavMenuRef1.current,
        {
          xPercent: -33,
          duration: 1,
          ease: Power2.easeInOut,
        },
        "s"
      )
      .to(
        dummyNavMenuRef2.current,
        {
          xPercent: -66,
          duration: 1,
          ease: Power2.easeInOut,
        },
        "s"
      )
      .to(
        dummyNavMenuRef3.current,
        {
          xPercent: -99,
          duration: 1,
          ease: Power2.easeInOut,
        },
        "s"
      )
      .to(
        [jdfxMenuLogo1Ref.current, jdfxMenuLogo2Ref.current],
        {
          rotation: -60,
          xPercent: -100,
          duration: 1,
          ease: Power2.easeInOut,
        },
        "s"
      )

    navMenuTL.current.play(1)
  }, [])

  const scrolllockStore = useScrollLockStore()

  const animateMenu = () => {
    if (navMenuTL.current.isActive()) {
      return
    }

    setCurrentLink(null)

    if (menuStore.open) {
      navMenuTL.current.reverse()
      sContext.lockScrollbar()
      setLocked(true)

      gsap.to(navMenuRef.current, {
        autoAlpha: 1,
        delay: 1,
        duration: 0,
        display: "flex",
        height: () => windowSize.height,
      })
    } else if (!menuStore.open) {
      navMenuTL.current.play()
      sContext.unlockScrollbar()
      setLocked(false)

      gsap.to(navMenuRef.current, {
        autoAlpha: 0,
        duration: 0,
        display: "none",
      })
    }
  }

  useEffect(() => {
    animateMenu()
  }, [menuStore.open])

  // useEffect(() => {
  //   if (navMenuRef.current && dummyNavMenuRef3 && dummyNavMenuRef2 && dummyNavMenuRef1) {
  //     navMenuRef.current.style.height = window.innerHeight
  //     dummyNavMenuRef1.current.style.height = window.innerHeight
  //     dummyNavMenuRef2.current.style.height = window.innerHeight
  //     dummyNavMenuRef3.current.style.height = window.innerHeight
  //   }
  // }, [windowSize])

  const location = useLocation()

  useEffect(() => {
    if (menuStore.open) menuStore.toggle()
  }, [location])

  return (
    <>
      <div className={cx(s.navMenu, s.real)} ref={navMenuRef}>
        <Link to="/">
          <img className={s.jdfxLogoMenu} src={logoMenu} alt="JUST DESIGN FX Logo" />
        </Link>

        <div className={s.linksWrapper} ref={linksWrapperRef}>
          <Link
            to="/about"
            className={s.navMenuItem}
            {...(windowSize.width > breakpoints.tablet && { onMouseOver: animateF })}
            {...(windowSize.width > breakpoints.tablet && { onMouseOut: animateR })}
          >
            <img ref={teamRef} src={aboutSvg} alt="Team" />
          </Link>
          <Link
            to="/works"
            className={s.navMenuItem}
            {...(windowSize.width > breakpoints.tablet && { onMouseOver: animateF2 })}
            {...(windowSize.width > breakpoints.tablet && { onMouseOut: animateR })}
          >
            <img ref={worksRef} src={worksSvg} alt="Works" />
          </Link>
          <Link
            to="/contact"
            className={s.navMenuItem}
            {...(windowSize.width > breakpoints.tablet && { onMouseOver: animateF3 })}
            {...(windowSize.width > breakpoints.tablet && { onMouseOut: animateR })}
          >
            <img ref={contactRef} src={contactSvg} alt="Let's Talk" />
          </Link>
          <img className={s.chooseWisely} src={chooseWisely} alt="Choose Wisely" />
        </div>
      </div>

      <div className={s.oHiddenWrapper} ref={oHiddenWrapperRef}>
        <div className={s.oHidden} ref={oh1Ref}>
          <div className={cx(s.navMenu, s.dummy)} ref={dummyNavMenuRef1} style={{ height: windowSize.height }}>
            <img ref={jdfxMenuLogo1Ref} className={s.jdfxLogoMenu} src={logoMenu} alt="JUST DESIGN FX Logo" />
            <div className={s.linksWrapper} ref={linksWrapper1Ref}>
              <div className={s.navMenuItem}>
                <img ref={teamRef1} src={aboutSvg} alt="" />
              </div>
              <div className={s.navMenuItem}>
                <img ref={worksRef1} src={worksSvg} alt="" />
              </div>
              <div className={s.navMenuItem}>
                <img ref={contactRef1} src={contactSvg} alt="" />
              </div>
              <img className={s.chooseWisely} src={chooseWisely} alt="Choose Wisely" />
            </div>
          </div>
        </div>
        <div className={s.oHidden} ref={oh2Ref}>
          <div className={cx(s.navMenu, s.dummy)} ref={dummyNavMenuRef2} style={{ height: windowSize.height }}>
            <img ref={jdfxMenuLogo2Ref} className={s.jdfxLogoMenu} src={logoMenu} alt="JUST DESIGN FX Logo" />
            <div className={s.linksWrapper} ref={linksWrapper2Ref}>
              <div className={s.navMenuItem}>
                <img ref={teamRef2} src={aboutSvg} alt="" />
              </div>
              <div className={s.navMenuItem}>
                <img ref={worksRef2} src={worksSvg} alt="" />
              </div>
              <div className={s.navMenuItem}>
                <img ref={contactRef2} src={contactSvg} alt="" />
              </div>
              <img className={s.chooseWisely} src={chooseWisely} alt="Choose Wisely" />
            </div>
          </div>
        </div>
        <div className={s.oHidden} ref={oh3Ref}>
          <div className={cx(s.navMenu, s.dummy)} ref={dummyNavMenuRef3} style={{ height: windowSize.height }}>
            <div className={s.linksWrapper} ref={linksWrapper3Ref}>
              <div className={s.navMenuItem}>
                <img ref={teamRef3} src={aboutSvg} alt="" />
              </div>
              <div className={s.navMenuItem}>
                <img ref={worksRef3} src={worksSvg} alt="" />
              </div>
              <div className={s.navMenuItem}>
                <img ref={contactRef3} src={contactSvg} alt="" />
              </div>
              <img className={s.chooseWisely} src={chooseWisely} alt="Choose Wisely" />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default React.memo(Menu)
