import { useEffect } from "react"
import s from "../assets/scss/Img.module.scss"

import cx from "classnames"
import LazyLoad from "vanilla-lazyload"

const conf = { elements_selector: ".lazy" }

// Only initialize it one time for the entire application
if (!document.hasOwnProperty("lazyLoadInstance")) {
  // @ts-ignore: Unreachable code error
  document.lazyLoadInstance = new LazyLoad(conf)
}

type Props = {
  src?: string
  objectFit?: any
  objectPosition?: string
  alt?: string
  lazy?: boolean
  width?: number
  height?: number
  bgColor?: string
}

const Img = ({
  src = "/",
  objectFit = "cover",
  objectPosition = "center",
  alt = "Commercial Media",
  lazy = false,
  width = 500,
  height = 500,
  bgColor = "transparent",
}: Props) => {
  // const [width, setWidth] = useState(1)
  // const [height, setHeight] = useState(1)

  useEffect(() => {
    // @ts-ignore: Unreachable code error
    document.lazyLoadInstance.update()
  })

  // Preload Images
  // useEffect(() => {
  //   if (!src) return

  //   const img = new Image()
  //   img.src = src
  //   img.onload = () => {
  //     console.log("image width", img.naturalWidth)
  //     console.log("image height", img.naturalHeight)

  //     setWidth(img.naturalWidth)
  //     setHeight(img.naturalHeight)
  //   }
  // }, [src])

  return (
    <figure className={s.figure} style={{ background: bgColor }}>
      <div
        className={s.aspectRatio}
        style={{ "--aspectRatio": `${(height / width) * 100}%` } as React.CSSProperties}
      ></div>
      {lazy ? (
        <img
          data-src={src}
          className={cx(s.img, "lazy")}
          style={{
            objectFit,
            objectPosition,
          }}
          alt={alt}
        />
      ) : (
        <img
          src={src}
          className={s.img}
          style={{
            objectFit,
            objectPosition,
          }}
          alt={alt}
        />
      )}
    </figure>
  )
}

export default Img
