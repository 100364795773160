import s from "assets/scss/WorkDetail.module.scss"
import React, { useEffect } from "react"

import api from "api"
import { useQuery } from "react-query"
import { useParams } from "react-router"
import { Helmet } from "react-helmet"

import otherCoolStuffs from "assets/img/other-cool-stuffs.svg"

import { IComponentData } from "api/types"
import AsymmetricMediaContainer from "components/AsymmetricMediaContainer"
import ImageReveal from "components/ImageReveal"
import MediaContainer from "components/MediaContainer"
import MediaSlider from "components/MediaSlider"
import WorkInfo from "components/WorkInfo"
import FooterMain from "components/FooterMain"
import Marquee from "components/Marquee"
import OtherWorks from "components/OtherWorks"
import MarqueeSimple from "components/MarqueeSimple"
import { useAllMediaStore } from "store/allMediaStore"
import { useWindowSize } from "hooks"
import { breakpoints } from "variables"

const Components = {
  workInfo: WorkInfo,
  mediaContainer: MediaContainer,
  asymmetricMediaContainer: AsymmetricMediaContainer,
  mediaSlider: MediaSlider,
  imageReveal: ImageReveal,
}

const WorkDetail = () => {
  const { workUrl: url } = useParams()
  const windowSize = useWindowSize()

  const fetchWorkInfo = async () => {
    const params = { url }
    const res = await api.get("/works/workDetail.php", {
      params,
    })
    return res.data
  }

  const { isLoading: isWorkInfoLoading, data: page } = useQuery<any, Error>(["work-info", url], fetchWorkInfo, {
    enabled: true,
    retry: 2,
  })

  const renderComponents = (block: IComponentData, index: number) => {
    // @ts-ignore: Unreachable code error
    if (typeof Components[block.component] !== "undefined") {
      // @ts-ignore: Unreachable code error
      return React.createElement(Components[block.component], {
        ...block.propData,
        key: index,
        workUrl: page.folderName,
      })
    }

    // component doesn't exist yet
    return React.createElement(() => <div>The component {block.component} has not been created yet.</div>, {
      key: index,
    })
  }

  const allMediaStore = useAllMediaStore()

  useEffect(() => {
    if (page) {
      const arr = [...page.allMedia]
      allMediaStore.setContent(arr)
      allMediaStore.setTitle(page.components[0].propData.title)
    }
  }, [page])

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        {page && !page.googleSnippet && <meta name="robots" content="nosnippet" />}
        <title>{`JUST DESIGN FX | ${(page && page.components[0].propData.title) || ""}`}</title>
        <link rel="canonical" href={`https://justdesignfx.com/works/${page && page.folderName}`} />
      </Helmet>

      <main className={s.workDetail}>
        {page &&
          Array.isArray(page.components) &&
          page.components.map((block: any, index: number) => {
            return renderComponents(block, index)
          })}

        <div className={s.marqueeContainer}>
          {windowSize.width <= breakpoints.tablet ? (
            <MarqueeSimple>
              <div className={s.mWrapper}>
                <div className={s.spanWrapper}>
                  <span className={s.rightMargin}>OTHER</span>
                  <span className={s.rightMargin}>
                    PR<span className={s.inlineO}>O</span>JECTS.
                  </span>
                </div>
              </div>
            </MarqueeSimple>
          ) : (
            <Marquee direction={-1}>
              <div className={s.mWrapper}>
                <div className={s.spanWrapper}>
                  <span className={s.rightMargin}>OTHER</span>
                  <span className={s.rightMargin}>
                    PR<span className={s.inlineO}>O</span>JECTS.
                  </span>
                </div>
              </div>
            </Marquee>
          )}

          <img src={otherCoolStuffs} className={s.otherCoolStuff} alt="See Other Cool Stuffs Smiley" />
        </div>

        {page && Array.isArray(page.otherWorks) && <OtherWorks works={[...page.otherWorks]}></OtherWorks>}
      </main>
      <FooterMain />
    </>
  )
}

export default WorkDetail
