import s from "assets/scss/AsymmetricMediaContainer.module.scss"
import { useRef } from "react"

import cx from "classnames"

import { IMediaData } from "api/types"
import { useWindowSize } from "hooks"
import { baseUrl, breakpoints, env } from "variables"
import Img from "./Img"

const AsymmetricMediaContainer = (props: IMediaData) => {
  const wrapperRef = useRef(null)
  const size = useWindowSize()

  return (
    <div className={cx(s.asymmetricMediaContainer, { [s.isLeft]: props.smallLeft })}>
      {props.items.length > 0 &&
        Array.isArray(props.items) &&
        props.items.map((item, index) => {
          return (
            <div
              key={index}
              className={s.mediaWrapper}
              ref={wrapperRef}
              style={{
                overflow: "hidden",
              }}
            >
              <Img
                src={`${baseUrl[env]}/jd-admin/assets/images/projects/${props.workUrl}/${
                  size.width <= breakpoints.mobile ? item.mediaData.mediaMobile.url : item.mediaData.mediaDesktop.url
                }`}
                alt="Commercial Product Visuals"
                lazy
                width={
                  size.width <= breakpoints.mobile
                    ? item.mediaData.mediaMobile.width
                    : item.mediaData.mediaDesktop.width
                }
                height={
                  size.width <= breakpoints.mobile
                    ? item.mediaData.mediaMobile.height
                    : item.mediaData.mediaDesktop.height
                }
              />
            </div>
          )
        })}
    </div>
  )
}

export default AsymmetricMediaContainer
