import s from "assets/scss/App.module.scss"

import cx from "classnames"
import gsap, { Power1 } from "gsap"

import Header from "components/Header"
import { WithSmooth } from "hocs/WithSmooth"
import { useWindowSize } from "hooks"

import { useEffect, useState } from "react"
import { Route, Routes, useLocation } from "react-router"
import ReactGA from "react-ga4"

import { useThemeStore } from "store/themeStore"

import About from "pages/About"
import Contact from "pages/Contact"
import Home from "pages/Home"
import WorkDetail from "pages/WorkDetail"
import Works from "pages/Works"
import { useCursorStore } from "store/cursorStore"
import { useFilterStore } from "store/filterStore"
import NotFound from "pages/NotFound"
import Unsubscribe from "pages/Unsubscribe"
import { Helmet } from "react-helmet"

function App() {
  const windowSize = useWindowSize()
  const location = useLocation()
  const [displayLocation, setDisplayLocation] = useState(location)
  const themeStore = useThemeStore()
  const cursorStore = useCursorStore()

  useEffect(() => {
    const TRACKING_ID = "G-G91YDRN3MT"
    ReactGA.initialize(TRACKING_ID)
  }, [])

  useEffect(() => {
    const transitionElements = Array.from(document.querySelectorAll("[data-route-transition]"))

    if (location !== displayLocation) {
      gsap.to(transitionElements, {
        opacity: 0,
        pointerEvents: "none",
        duration: 0.3,
        ease: Power1.easeInOut,
        onComplete: () => {
          window.scrollTo(0, 0)
          gsap.to(transitionElements, {
            opacity: 1,
            pointerEvents: "auto",
            duration: 0.4,
            delay: 1,
            ease: Power1.easeInOut,
          })
          setDisplayLocation(location)
        },
      })
    }
  }, [location, displayLocation, windowSize.width])

  useEffect(() => {
    if (!cursorStore.default) cursorStore.toggleDefault()
  }, [location])

  const { resetFilters } = useFilterStore()

  useEffect(() => {
    if (location.pathname !== "/works") {
      resetFilters()
    }
  }, [location])

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>JUST DESIGN FX | Branding, Packaging, and Web Design</title>
        <meta
          name="description"
          content="We design iconic brands with future impact. JUST DESIGN FX is a future-focused brand and digital design agency with offices located in Istanbul and New York."
        ></meta>
        <link rel="canonical" href="https://justdesignfx.com/" />
      </Helmet>
      <WithSmooth location={displayLocation}>
        <div className={cx(s.content, { [s.dark]: themeStore.theme === "DARK" })} data-route-transition>
          <Header />
          <Routes location={displayLocation}>
            <Route index element={<Home />}></Route>
            <Route path="works" element={<Works />}></Route>
            <Route path="works/:workUrl" element={<WorkDetail />} />
            <Route path="contact" element={<Contact />}></Route>
            <Route path="about" element={<About />}></Route>
            <Route path="unsubscribe/:email" element={<Unsubscribe />}></Route>
            <Route path="*" element={<NotFound />}></Route>
          </Routes>
        </div>
      </WithSmooth>
    </>
  )
}

export default App
