import s from "assets/scss/AllMediaSlider.module.scss"
import cx from "classnames"
import React, { useEffect, useRef, useState } from "react"

// import Swiper core and required modules
import { Navigation } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"

import { ReactComponent as RightArrow } from "assets/icon/icon-white-right-arrow.svg"

// Import Swiper styles
import { useSwiperRef, useWindowSize } from "hooks"

import "swiper/css"
import "swiper/css/navigation"
import { breakpoints } from "variables"
import Img from "./Img"

const SliderVideoPlayer = (props: any) => {
  const size = useWindowSize()
  const videoRef = useRef<HTMLVideoElement>(null)

  // console.log("SLIDER VIDEO", props.item, props.index, props.currentSlide)

  useEffect(() => {
    if (!videoRef.current) {
      return
    }

    videoRef.current.pause()

    if (props.currentSlide === props.index) {
      videoRef.current.play()
    } else {
      videoRef.current.pause()
    }
  }, [props.currentSlide])

  return (
    <>
      {size.width > breakpoints.tablet ? (
        <video
          ref={videoRef}
          className={s.video}
          src={
            size.width <= breakpoints.mobile
              ? props.item.mediaData.mediaMobile.url
              : props.item.mediaData.mediaDesktop.url
          }
          controls
        ></video>
      ) : (
        <video className={s.video} controls>
          <source
            src={`${
              size.width <= breakpoints.mobile
                ? props.item.mediaData.mediaMobile.url
                : props.item.mediaData.mediaDesktop.url
            }#t=0.001`}
            type="video/mp4"
          />
        </video>
      )}
    </>
  )
}

const AllMediaSlider = ({ items, title, activeSlide }: any) => {
  const size = useWindowSize()
  const [currentSlide, setCurrentSlide] = useState(0)
  const [nextEl, nextElRef] = useSwiperRef<HTMLButtonElement>()
  const [prevEl, prevElRef] = useSwiperRef<HTMLButtonElement>()

  return (
    <div className={s.allMediaSlider}>
      <div className={s.title}>
        <h2>{title}</h2>
      </div>
      <div className={s.slider}>
        <Swiper
          style={{ height: "100%", width: "100%" }}
          modules={[Navigation]}
          onSlideChange={(e: any) => setCurrentSlide(e.activeIndex)}
          slidesPerView={1}
          initialSlide={activeSlide}
          centeredSlides={true}
          spaceBetween={0}
          speed={600}
          navigation={{
            prevEl,
            nextEl,
            disabledClass: "hidden",
          }}
        >
          {items.map((item: any, i: number) => {
            return (
              <React.Fragment key={i}>
                {item.mediaType === "video" ? (
                  <SwiperSlide>
                    <div className={s.mediaWrapper}>
                      <SliderVideoPlayer item={item} index={i} currentSlide={currentSlide} />
                    </div>
                  </SwiperSlide>
                ) : (
                  <SwiperSlide>
                    <div className={s.mediaWrapper}>
                      <Img
                        src={
                          size.width <= breakpoints.mobile
                            ? item.mediaData.mediaMobile.url
                            : item.mediaData.mediaDesktop.url
                        }
                        width={
                          size.width <= breakpoints.mobile
                            ? item.mediaData.mediaMobile.width
                            : item.mediaData.mediaDesktop.width
                        }
                        height={
                          size.height <= breakpoints.mobile
                            ? item.mediaData.mediaMobile.height
                            : item.mediaData.mediaDesktop.height
                        }
                        alt="Commercial Product Visuals"
                        objectFit="contain"
                        bgColor="transparent"
                        lazy
                      />
                    </div>
                  </SwiperSlide>
                )}
              </React.Fragment>
            )
          })}
        </Swiper>

        <button className={cx(s.btnWrapper, s.btnPrev, { [s.disabled]: currentSlide === 0 })} ref={prevElRef}>
          <div className={s.btnInner}>
            <RightArrow className={cx(s.btnArrow, s.prev)} />
          </div>
        </button>

        <button
          className={cx(s.btnWrapper, s.btnNext, { [s.disabled]: currentSlide === items.length - 1 })}
          ref={nextElRef}
        >
          <div className={s.btnInner}>
            <RightArrow className={cx(s.btnArrow, s.next)} />
          </div>
        </button>
      </div>
    </div>
  )
}

export default AllMediaSlider
