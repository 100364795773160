import s from "assets/scss/WorkCardHome.module.scss"
import { Link } from "react-router-dom"

import { IProjectCard } from "api/types"
import { useCursorStore } from "store/cursorStore"
import { breakpoints } from "variables"
import { useWindowSize } from "hooks"

const WorkCardHome = (props: IProjectCard) => {
  const size = useWindowSize()
  const cursorStore = useCursorStore()

  return (
    <div className={s.projectCard}>
      <Link
        to={`/works/${props.url}`}
        className={s.imgContainer}
        {...(size.width > breakpoints.tablet && {
          onMouseEnter: () => {
            cursorStore.toggleText()
          },
        })}
        {...(size.width > breakpoints.tablet && {
          onMouseLeave: () => {
            cursorStore.toggleText()
            cursorStore.toggleDefault()
          },
        })}
      >
        {props.mediaType === "video" ? (
          <video
            className={s.projectVid}
            src={size.width > breakpoints.mobile ? props.mediaSrcDesktop : props.mediaSrcMobile}
            autoPlay
            playsInline
            loop
            muted
          ></video>
        ) : (
          <>
            <img
              className={s.projectImg}
              src={size.width > breakpoints.mobile ? props.mediaSrcDesktop : props.mediaSrcMobile}
              alt="Project Visual"
            />
            {props.awardImage && <img className={s.award} src={props.awardImage} alt="Award Visual" />}
          </>
        )}
      </Link>
      <h3 className={s.cardTitle}>{props.title}</h3>
      <div className={s.cardTagWrapper}>
        {props.services.map((category, index) => {
          return (
            <small key={index} className={s.cardTag}>
              {category}
            </small>
          )
        })}
      </div>
    </div>
  )
}

export default WorkCardHome
