import { create } from "zustand"

type Dark = "DARK"
type Light = "LIGHT"

interface ThemeState {
  theme: Dark | Light
  setTheme: (selectedTheme: Dark | Light) => void
}

const useStore = create<ThemeState>((set) => ({
  theme: "DARK",
  setTheme: (selectedTheme) => set({ theme: selectedTheme }),
}))

export const useThemeStore = useStore
