import s from "assets/scss/MediaSlider.module.scss"

import cx from "classnames"

// import Swiper core and required modules
import { Autoplay, Navigation } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"

import { ReactComponent as RightArrow } from "assets/icon/icon-white-right-arrow.svg"

// Import Swiper styles
import { useSwiperRef, useWindowSize } from "hooks"
import React, { useState } from "react"

import "swiper/css"
import "swiper/css/navigation"

import { IMediaData } from "api/types"
import Img from "./Img"
import Vid from "./Vid"
import { baseUrl, breakpoints, env } from "variables"

const MediaSlider = (props: IMediaData) => {
  const [currentSlide, setCurrentSlide] = useState(0)
  const [nextEl, nextElRef] = useSwiperRef<HTMLButtonElement>()
  const [prevEl, prevElRef] = useSwiperRef<HTMLButtonElement>()
  const size = useWindowSize()

  return (
    <div className={s.mediaSlider}>
      <Swiper
        style={{ width: "100%", height: "100%" }}
        modules={[Navigation, Autoplay]}
        onSlideChange={(e: any) => setCurrentSlide(e.activeIndex)}
        slidesPerView={1}
        spaceBetween={0}
        speed={600}
        navigation={{
          prevEl,
          nextEl,
          disabledClass: "hidden",
        }}
        autoplay={{
          delay: 2000,
          // pauseOnMouseEnter: true,
        }}
        loop={true}
      >
        {Array.isArray(props.items) &&
          props.items.map((item, i) => {
            return (
              <React.Fragment key={i}>
                {item.mediaType === "video" ? (
                  <SwiperSlide>
                    <div className={s.mediaWrapper}>
                      <div className={s.video}>
                        <Vid
                          src={item.mediaData.mediaDesktop.url}
                          width={
                            size.width <= breakpoints.mobile
                              ? item.mediaData.mediaDesktop.width
                              : item.mediaData.mediaDesktop.width
                          }
                          height={
                            size.height <= breakpoints.mobile
                              ? item.mediaData.mediaDesktop.height
                              : item.mediaData.mediaDesktop.height
                          }
                          lazy
                          bgColor="#dedede"
                        />
                      </div>

                      {/* <video className={s.video} src={item.mediaSrc} muted autoPlay loop controls></video> */}
                    </div>
                  </SwiperSlide>
                ) : (
                  <SwiperSlide>
                    <div className={s.mediaWrapper}>
                      <div className={s.img}>
                        <Img
                          src={`${baseUrl[env]}/jd-admin/assets/images/projects/${props.workUrl}/${item.mediaData.mediaDesktop.url}`}
                          width={
                            size.width <= breakpoints.mobile
                              ? item.mediaData.mediaDesktop.width
                              : item.mediaData.mediaDesktop.width
                          }
                          height={
                            size.height <= breakpoints.mobile
                              ? item.mediaData.mediaDesktop.height
                              : item.mediaData.mediaDesktop.height
                          }
                          lazy
                          bgColor="#dedede"
                        />
                      </div>

                      {/* <img
                        className={s.img}
                        src={`https://justdesignfx.com/jd-admin/assets/images/projects/${props.workUrl}/${item.mediaSrc}`}
                        alt="sample"
                      /> */}
                    </div>
                  </SwiperSlide>
                )}
              </React.Fragment>
            )
          })}
      </Swiper>

      <button
        className={cx(
          s.btnWrapper,
          s.btnPrev
          // { [s.disabled]: currentSlide === 0 }
        )}
        ref={prevElRef}
      >
        <div className={s.btnInner}>
          <RightArrow className={cx(s.btnArrow, s.prev)} />
        </div>
      </button>

      <button
        className={cx(
          s.btnWrapper,
          s.btnNext
          // { [s.disabled]: currentSlide === props.items.length - 1 }
        )}
        ref={nextElRef}
      >
        <div className={s.btnInner}>
          <RightArrow className={cx(s.btnArrow, s.next)} />
        </div>
      </button>
    </div>
  )
}

export default MediaSlider
